import { Scene, Mesh, MeshStandardMaterial } from "three";

import Sizes from "./Utils/Sizes.js";
import Time from "./Utils/Time.js";
import MobileDetector from "./Utils/MobileDetector.js";
import Disposer from "./Utils/Disposer.js";
import Keys from "./Utils/Keys.js";
import Joystick from "./Utils/Joystick.js";
import Resources from "./Utils/Resources.js";
import Colorize from "./Utils/Colorize.js";
import Camera from "./Camera.js";
import Audio from "./Audio.js";
import Renderer from "./Renderer.js";
import Composer from "./Composer.js";
import Pointer from "./Utils/Pointer.js";
import Environment from "./World/Environment.js";
import Pavilion from "./World/Pavilion.js";
import Player from "./Characters/Player.js";
import Colliders from "./World/Colliders.js";
import Navmesh from "./World/Navmesh.js";
import Minimap from "./World/Minimap.js";

import sources from "./sources.js";

// Global instance
let instance = null;
// Time variables
let delta, interval;

// FPS test
(function () { var script = document.createElement('script'); script.onload = function () { var stats = new Stats(); document.body.appendChild(stats.dom); requestAnimationFrame(function loop() { stats.update(); requestAnimationFrame(loop) }); }; script.src = '//mrdoob.github.io/stats.js/build/stats.min.js'; document.head.appendChild(script); })();

export default class Experience
{
    // Set constructor
    constructor(canvas, parameters)
    {
        // Return the existing instance
        if(instance) return instance;
        // Create a new instance if needed
        instance = this;

        delta = 0;
        interval = 1 / 30;

        // Get canvas
        this.canvas = canvas;

        // Scene loaded verifier
        this.SCENE_LOADED = false;
        // Render quality
        this.QUALITY = 1;

        // Init parameters object
        this.parameters = {};

        // Set the assets path inside the project
        this.parameters.ASSETS_PATH = typeof parameters.ASSETS_PATH == 'undefined' ? '' : parameters.ASSETS_PATH;
        // Set the camera mode
        this.parameters.FIRST_PERSON_CAM = typeof parameters.FIRST_PERSON_CAM == 'undefined' ? true : parameters.FIRST_PERSON_CAM;
        // Set muted audio verifier
        this.parameters.AUDIO_MUTED = typeof parameters.AUDIO_MUTED == 'undefined' ? false : parameters.AUDIO_MUTED;

        // Set persistens utils
        this.#setPersistentUtils();
        // Set utils that can be temporarily destroyed
        this.#setUtils();
    }

    // Method called to set up the persistent classes
    #setPersistentUtils()
    {
        // Setup utils
        this.sizes = new Sizes(this.canvas);
        this.time = new Time();
        this.mobileDetector = new MobileDetector();
        this.disposer = new Disposer();
        // Load resources
        this.resources = new Resources(sources, this.parameters.ASSETS_PATH);
    }

    // Method called to set up the needed classes
    #setUtils()
    {
        // Setup keys
        this.keys = new Keys();
        // If the device is a mobile, setup joysticks
        if(this.mobileDetector.isMobile) this.joystick = new Joystick();

        // Create scene
        this.scene = new Scene();
        // Setup environment
        this.environment = new Environment();

        // Set colorizer
        this.colorize = new Colorize();

        // Setup camera and rendering
        this.camera = new Camera(this.parameters.FIRST_PERSON_CAM);
        this.audio = new Audio(this.parameters.AUDIO_MUTED);
        this.renderer = new Renderer();
        this.composer = new Composer();
        this.pointer = new Pointer();

        // Setup world colliders
        this.colliders = new Colliders();

        // Setup pavilion and navmesh
        this.minimap = new Minimap();
        this.pavilion = new Pavilion();
        this.navmesh = new Navmesh();

        // Setup player
        this.player = new Player();

        // Set listeners
        this.#setListeners();
    }

    // Private method called to set all the listeners
    #setListeners()
    {
        // Listen for resize event
        this.sizes.on('resize', () =>
        {
            // Resize the aim
            let root = document.documentElement;
            root.style.setProperty('--top-aim', (this.renderer.instance.domElement.clientHeight / 2) + this.sizes.marginTop + 'px');
            root.style.setProperty('--left-aim', (this.renderer.instance.domElement.clientWidth / 2) + this.sizes.marginLeft + 'px');

            // Call local resize method
            this.resize();
        });

        // Time tick event
        this.time.on('tick', () =>
        {
            // Update delta
            delta += this.time.delta / 1000;
            // If the delta time surpassed the interval needed
            if(delta > interval)
            {
                // If the scene is loaded
                if(this.SCENE_LOADED === true)
                {
                    // Call local update method
                    this.update();
                }
                
                // Reset delta
                delta = delta % interval;
            }
        });

        // Listen for loaded resources event
        this.pavilion.on('loaded3DScene', () =>
        {
            // Set verification variable to true
            this.SCENE_LOADED = true;
            // Update shadow map
            this.renderer.instance.shadowMap.needsUpdate = true;

            // Data to be sent
            const data = { 'scene_id': 3 }

            // Trigger event once to signal that all the content has been loaded
            const loaded3DSceneEvent = new CustomEvent( 'loaded3DScene', { detail: data } );
            window.novvaC3.eventTarget.dispatchEvent(loaded3DSceneEvent);
        });

        // Listen to when the stands are ready
        this.pavilion.on('loaded3DModel', () =>
        {
            // Data to be sent
            const data = { 'section_id': this.pavilion.instance.id }

            // Trigger event once to signal that the stand has been loaded
            const loaded3DModelEvent = new CustomEvent( 'loaded3DModel', { detail: data } );
            window.novvaC3.eventTarget.dispatchEvent(loaded3DModelEvent);
        });

        this.pavilion.on('standsReady', () =>
        {
            // If the render quality is medium or high
            if(this.QUALITY > 0)
            {
                // Update all materials
                this.updateMaterialsEnvMapIntensity(this.environment.envMapIntensity);
            }

            // Trigger event once to signal that the stand has been loaded
            const loaded3DStandsEvent = new CustomEvent( 'loaded3DStands' );
            window.novvaC3.eventTarget.dispatchEvent(loaded3DStandsEvent);
        });
    }

    // Method called externally to change the graphics quality
    updateRenderQuality(id)
    {
        try
        {
            // If the id is not valid, throw an exception
            if(id !== 0 && id !== 1 && id !== 2) throw 'Render quality value is not a valid number (0, 1 or 2): ' + id;
        
            // If the chosen quality isn't the same as the current quality
            if(this.QUALITY !== id)
            {
                // Set new quality
                this.QUALITY = id;
                
                this.composer.clearComposer();
                // Generate new composer
                this.composer.setComposer();

                // If the render quality is medium or high
                if(this.QUALITY > 0)
                {
                    // Set environment map
                    this.environment.setEnvironmentMap();
                    // Update all materials
                    this.updateMaterialsEnvMapIntensity(this.environment.envMapIntensity);
                }
                // If the render quality is low
                else
                {
                    // Update all materials
                    this.updateMaterialsEnvMapIntensity(0);
                    // Remove environment map
                    this.scene.environment = null;
                    // Reset hemisphere light intensity
                    this.environment.hemisphereLight.intensity = 1;
                }
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Method called to update the environment map intensity of all the scene elements
    updateMaterialsEnvMapIntensity(intensity)
    {
        this.scene.traverse((object) =>
        {
            // If object is a mesh and their material is a standard material
            if(object instanceof Mesh && object.material instanceof MeshStandardMaterial)
            {
                // Set environment map intensity
                object.material.envMapIntensity = intensity;
            }
        });
    }

    // Method called to update the footsteps volume
    setFootstepsVolume(value)
    {
        // Set volume
        this.audio.volume[2] = value;

        // Set the player footsteps volume
        this.player.footstepsManager.volume = this.audio.volume[2];
        // Set the NPCs footsteps volume
        this.pavilion.instance.npcs.forEach(npc =>
        {
            // If the NPC is dynamic, update their footsteps' volume
            if(npc.instance.static === false)
            {
                // If the volume is above zero
                if(this.audio.volume[2] > 0)
                {
                    // Reduce the NPCs volume, with a minimum value of 0.05
                    npc.footstepsManager.volume = Math.max((this.audio.volume[2] - 0.2), 0.05);
                }
                else npc.footstepsManager.volume = this.audio.volume[2];
            }
        });
    }

    // Method called when the screen is resized
    resize()
    {
        // Propagate to the classes that need to be updated
        this.camera.resize();
        this.renderer.resize();
        this.composer.resize();
    }

    // Method called by the tick event that propagates it to the classes that need updates every tick
    update()
    {
        // Update player
        this.player.update();
        // Update pavilion
        this.pavilion.update();
        // Update composer
        this.composer.update();
    }

    // Method called to restart the scene after a destroy
    restart()
    {
        // Set utils that werw temporarily destroyed
        this.#setUtils();
        // Trigger loaded resources
        this.resources.trigger('loadedResources');
    }

    // Method called to destroy the scene
    destroy()
    {
        // Set scene to unloaded
        this.SCENE_LOADED = false;

        // Exit pointer lock
        document.exitPointerLock();

        // Stop listening for the events
        this.sizes.off('resize');
        this.time.off('tick');
        this.pavilion.off('loaded3DScene');
        this.pavilion.off('loaded3DModel');
        this.pavilion.off('standsReady');

        // Propagate the destroy method
        this.keys.destroy();
        if(this.mobileDetector.isMobile) this.joystick.destroy();
        this.pointer.destroy();
        this.environment.destroy();
        this.colorize.destroy();
        this.camera.destroy();
        this.audio.destroy();
        this.composer.destroy();
        this.minimap.destroy();
        this.pavilion.destroy();
        this.navmesh.destroy();
        this.player.destroy();

        // Clear renderer
        this.renderer.instance.renderLists.dispose();
        this.renderer.instance.clear();
        // Dispose the renderer instance
        this.renderer.instance.dispose();
        this.renderer.destroy();

        // Dispose scene
        this.disposer.disposeElements(this.scene);
        this.scene.clear();

        // Remove utils
        this.keys = null;
        this.camera = null;
        this.audio = null;
        this.renderer = null;
        this.composer = null;
        this.pointer = null;
        this.environment = null;
        this.colorize = null;
        this.joystick = null;
        this.colliders = null;
        this.minimap = null;
        this.pavilion = null;
        this.navmesh = null;
        this.player = null;

        // Reset scene
        this.scene = null;
    }

    // Method called to wipe the mempory clear
    wipe()
    {
        // Destroy the scene
        this.destroy();

        // Dispose loaded assets
        Object.keys(this.resources.items).forEach((item) =>
        {
            this.disposer.disposeElements(this.resources.items[item], true);
        });
        this.resources.destroy();

        // Remove utils
        this.sizes = null;
        this.time = null;
        this.mobileDetector = null;
        this.disposer = null;
        this.resources = null;

        // Reset variables
        this.canvas = null;

        // Reset instance
        instance = null;
    }
}