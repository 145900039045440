export default class MobileDetector
{
    // Set constructor
    constructor()
    {
        this.detectMobile();
    }

    // Method called to verify if the user device is a mobile
    detectMobile()
    {
        this.isMobile = false;

        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua))
        {
            // Mobile
            this.isMobile = true;
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua))
        {
            // Tablet
            this.isMobile = true
        }
    }
}