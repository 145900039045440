import { PerspectiveCamera, Vector2, Vector3 } from "three";

import Experience from "./Experience.js";
import EventEmitter from './Utils/EventEmitter.js';

let isMobile;

export default class Camera extends EventEmitter
{
    // Set constructor
    constructor(FIRST_PERSON_CAM)
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.canvas = this.experience.canvas;
        this.keys = this.experience.keys;

        // Set parameters
        this.FIRST_PERSON_CAM = FIRST_PERSON_CAM;

        // Set camera instances
        this.setInstances();
    }

    // Method called to create and set up the cameras
    setInstances()
    {
        // Get the mobile detector class from the experience
        this.mobileDetector = this.experience.mobileDetector;
        // Get if the device is a mobile
        isMobile = this.mobileDetector.isMobile;
        // Remove reference
        this.mobileDetector = null;

        // Create first person perspective camera
        this.fpCamera = new PerspectiveCamera(50, this.sizes.width / this.sizes.height, 0.1, 2000);
        this.fpCamera.rotation.order = 'YXZ';
        this.fpCamera.position.set(55, 1.5, 0);
        this.fpCamera.lookAt(new Vector3(0, 1.5, 0));
        this.fpCamera.name = "FistPersonCamera";

        // Create third person perspective camera
        this.tpCamera = new PerspectiveCamera(45, this.sizes.width / this.sizes.height, 0.1, 2000);
        this.tpCamera.position.set(70, 70, 0);
        this.tpCamera.name = "ThirdPersonCamera";
        this.tpCamera.lookAt(new Vector3(10, 0, 0));

        // Get camera center
        this.cameraCenter = new Vector2(this.tpCamera.position.x, this.tpCamera.position.z);

        // Add cameras to scene
        this.scene.add(this.fpCamera, this.tpCamera);

        // Remove scene reference
        this.scene = null;

        if(this.FIRST_PERSON_CAM === true)
        {
            // Set main camera as the first person camera
            this.renderCamera = this.fpCamera;
        }
        else
        {
            // Set main camera as the third person camera
            this.renderCamera = this.tpCamera;
        }

        // Listen for the switch camera command
        this.keys.on('switchCamera', () =>
        {
            // Get the pointer class if the current reference is empty
            if(!this.pointer) this.pointer = this.experience.pointer;

            // Call the method to switch cameras
            this.switchCamera();
        });
    }

    // Method called to switch between the two cameras
    switchCamera()
    {
        // Switch from first to third person camera
        if(this.renderCamera === this.tpCamera)
        {
            // Set current camera to first person camera
            this.renderCamera = this.fpCamera;

            // Set global variable used by the C4 to true
            this.FIRST_PERSON_CAM = true;

            // If the device is a desktop
            if(isMobile === false)
            {
                // Request pointer lock
                this.canvas.requestPointerLock();
            }
            // If the device is a mobile
            else
            {
                // Show the aim
                this.pointer.aim.style.display = "";
            }
        }
        // Switch from third to first person camera
        else if(this.renderCamera === this.fpCamera)
        {
            // Set current camera to third person camera
            this.renderCamera = this.tpCamera;

            // Set global variable used by the C4 to false
            this.FIRST_PERSON_CAM = false;

            // If the device is a desktop
            if(isMobile === false)
            {
                // Request pointer to exit lock
                document.exitPointerLock();
            }
            // If the device is a mobile
            else
            {
                // Hide the aim
                this.pointer.aim.style.display = "none";
            }
        }

        if(!this.renderer) this.renderer = this.experience.renderer;
        // Update shadow map
        this.renderer.instance.shadowMap.needsUpdate = true;

        // Data to be sent
        const data = { 'first_person_mode': this.FIRST_PERSON_CAM };

        // Trigger event once to signal that the stand has been loaded
        const cameraModeEvent = new CustomEvent( 'switchingCameraMode', { detail: data } );
        window.novvaC3.eventTarget.dispatchEvent(cameraModeEvent);
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Update first person camera aspect
        this.fpCamera.aspect = this.sizes.width / this.sizes.height;
        this.fpCamera.updateProjectionMatrix();

        // Update third person camera aspect
        this.tpCamera.aspect = this.sizes.width / this.sizes.height;
        this.tpCamera.updateProjectionMatrix();
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Stop listening for the switch camera event
        this.keys.off('switchCamera');

        // Reset variables
        this.fpCamera = null;
        this.tpCamera = null;
        this.renderCamera = null;
        this.cameraCenter = null;
        this.FIRST_PERSON_CAM = null;

        // Remove references
        this.experience = null;
        this.sizes = null;
        this.canvas = null;
        this.keys = null;
        if(this.pointer) this.pointer = null;
    }
}