import { Vector2 } from "three";

import Experience from "../Experience.js";
import EventEmitter from './EventEmitter.js';

let lockChangeHandler, touchHandler, mouseDownHandler, mouseMoveHandler, mouseUpHandler;

let pointerDown, pointerMoved, startPos;
let isMobile;

export default class Pointer extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.canvas = this.experience.canvas;
        this.sizes = this.experience.sizes;
        this.audio = this.experience.audio;
        this.resources = this.experience.resources;
        this.camera = this.experience.camera;
        this.player = this.experience.player;

        pointerDown = false;
        pointerMoved = false;
        startPos = new Vector2(0, 0);

        // Set pointer
        this.setPointer();

        // When the resources are loaded
        this.resources.on('loadedResources', () =>
        {
            // Get the needed classes from the experience
            this.player = this.experience.player;
            this.pavilion = this.experience.pavilion;
            this.minimap = this.experience.minimap;
        });
    }

    // Method called to set up the pointer
    setPointer()
    {
        // Mouse position
        this.mouse = new Vector2(Infinity, Infinity);

        // Get the mobile detector class from the experience
        this.mobileDetector = this.experience.mobileDetector;
        // Get if the device is a mobile
        isMobile = this.mobileDetector.isMobile;
        // Remove reference
        this.mobileDetector = null;

        // Get aim element
        this.aim = document.getElementById('firts-person-aim');
        this.aim.src = this.resources.ASSETS_PATH + 'images/icons/aim.png';

        // If the device is a desktop
        if(isMobile === false)
        {
            // Mouse status
            this.mouseMove = false;
            // Set mouse move listener
            this.#setMouseListeners();

            // Pointer status
            this.locked = false;
            // Set listeners related to the pointer locking and unlocking
            this.#setPointerLockListeners();
        }
        // If the device is a mobile
        else
        {
            // Activate aim
            if(this.camera.FIRST_PERSON_CAM === false) this.aim.style.display = 'none';
            else this.aim.style.display = '';

            // Set touch listeners
            this.#setTouchListeners();
        }
    }

    // Private method called to set up listeners related to the mouse movement
    #setMouseListeners()
    {
        // Mouse down event handler
        mouseDownHandler = (e) =>
        {
            // If the experience has loaded
            if(this.experience.SCENE_LOADED === true)
            {
                // If this is the first click
                if(this.audio.firstClick === false)
                {
                    // Play audio if not muted
                    this.audio.firstClick = true;
                    if(this.audio.AUDIO_MUTED === false) this.audio.pauseOrResumeSoundtrack(0, true);
                }

                // If the click target is the canvas
                if(e.target === this.canvas)
                {
                    // Set variable to true to alert that the mouse has been pressed
                    pointerDown = true;

                    // Get mouse position
                    startPos.x = ((e.clientX - this.sizes.marginLeft) / this.sizes.width) * 2 - 1;
                    startPos.y = -((e.clientY - this.sizes.marginTop) / this.sizes.height) * 2 + 1;
                }
            }
        };

        // Mouse move event handler
        mouseMoveHandler = (e) =>
        {
            if(this.experience.SCENE_LOADED === true && e.target === this.canvas)
            {
                // Set mouse status as moving
                this.mouseMove = true;

                // Get mouse position
                this.mouse.x = ((e.clientX - this.sizes.marginLeft) / this.sizes.width) * 2 - 1;
                this.mouse.y = -((e.clientY - this.sizes.marginTop) / this.sizes.height) * 2 + 1;

                // If the third person camera is active
                if(!this.camera.FIRST_PERSON_CAM && pointerDown === true)
                {
                    // Move camera accordingly to the mouse position
                    this.camera.tpCamera.position.x -= (e.movementY / 100);
                    this.camera.tpCamera.position.y = 70;
                    this.camera.tpCamera.position.z += (e.movementX / 100);

                    // Set limits to the camera vertically
                    if(this.camera.tpCamera.position.x < 64) this.camera.tpCamera.position.x = 64;
                    else if(this.camera.tpCamera.position.x > 72) this.camera.tpCamera.position.x = 72;
                    // Set limits to the camera horizontally
                    if(this.camera.tpCamera.position.z > 25) this.camera.tpCamera.position.z = 25;
                    else if(this.camera.tpCamera.position.z < -25) this.camera.tpCamera.position.z = -25;
                }
                // If the first person camera is active and the mouse is locked to the canvas
                else if(document.pointerLockElement === this.canvas)
                {
                    // Rotate camera as the mouse moves
                    this.camera.fpCamera.rotation.y -= e.movementX / 800;
                    this.camera.fpCamera.rotation.x -= e.movementY / 800;

                    // Add limits to the camera rotation
                    if(this.camera.fpCamera.rotation.x < -(Math.PI * 0.3)) this.camera.fpCamera.rotation.x = -(Math.PI * 0.3);
                    if(this.camera.fpCamera.rotation.x > (Math.PI * 0.4)) this.camera.fpCamera.rotation.x = (Math.PI * 0.4);
                }
            }
        };

        // Mouse up event handler
        mouseUpHandler = (e) =>
        {
            if(this.experience.SCENE_LOADED === true && e.target === this.canvas)
            {
                // Set variable to true to alert that the mouse has been released
                pointerDown = false;

                // Get mouse position
                this.mouse.x = ((e.clientX - this.sizes.marginLeft) / this.sizes.width) * 2 - 1;
                this.mouse.y = -((e.clientY - this.sizes.marginTop) / this.sizes.height) * 2 + 1;

                // If the scene is loaded and the mouse click hasn't moved
                if(this.experience.SCENE_LOADED && this.equals(startPos, this.mouse, 0.02) === true)
                {
                    // If the first person camera is active and the pointer isn't locked
                    if(this.camera.FIRST_PERSON_CAM === true && this.locked === false)
                    {
                        // Trigger event warning that the pointer can be locked
                        const canLockEvent = new CustomEvent( 'lockPointer' );
                        window.novvaC3.eventTarget.dispatchEvent(canLockEvent);
                    }

                    // Update camera
                    this.camera.renderCamera.updateMatrixWorld();

                    // If the user is hovering a stand during the click
                    if(this.player.instance.hoveredStand !== null)
                    {
                        // If the first person camera is active and locked or the third person camera is active
                        if((this.camera.FIRST_PERSON_CAM && this.locked) || (!this.camera.FIRST_PERSON_CAM))
                        {
                            let data;

                            // It the object intersected is a central totem button
                            if(this.player.instance.hoveredStand.includes('pav'))
                            {
                                // Get section id
                                const section = this.minimap.getSectionIdByDirection(this.player.instance.hoveredStand.split('_')[1]);
                                
                                // If there is a section in this direction
                                if(section !== undefined)
                                {
                                    const id = parseInt(section.split('_')[1]);

                                    // Data to be sent
                                    data = {
                                        'pavilion_id': this.pavilion.instance.info.pavilion_id,
                                        'current_section_id': this.pavilion.instance.id,
                                        'target_section_id': this.pavilion.instance.info.sections[id].section_id
                                    }

                                    // Trigger a click event on the respective minimap section
                                    this.minimap.instance.sections[id].click();
                                }
                                else
                                {
                                    // Trigger event warning that the exit button in the totem was clicked
                                    const exitEvent = new CustomEvent( 'exitPavilion' );
                                    window.novvaC3.eventTarget.dispatchEvent(exitEvent);
                                }
                            }
                            // It the object intersected is a stand
                            else
                            {
                                // Get add factor
                                let add = 0;
                                if(this.player.instance.hoveredStand.includes('bullet')) add = 1;

                                // Get the stand id
                                const id = parseInt(this.player.instance.hoveredStand.split('_')[1 + add]);

                                // Data to be sent
                                data = {
                                    'pavilion_id': this.pavilion.instance.info.pavilion_id,
                                    'section_id': this.pavilion.instance.id,
                                    'stand_id': this.pavilion.instance.standsInfo.stands[id].stand_id,
                                    'player_position': this.player.instance.collider.end,
                                    'player_rotation': this.camera.fpCamera.rotation,
                                    'first_person_mode': this.camera.FIRST_PERSON_CAM
                                }
                            }

                            // If the data isn't empty
                            if(data !== undefined)
                            {
                                // Trigger event warning that this stand was clicked, sending the info acquired
                                const standClickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
                                window.novvaC3.eventTarget.dispatchEvent(standClickEvent);
                            }
                        }

                        // Set cursor to pointer
                        document.body.style.cursor = "default";
                    }
                }
            }
        };

        // Listen for mouse clicks
        document.addEventListener('mousedown', mouseDownHandler);
        // Listen for mouse movements
        document.addEventListener('mousemove', mouseMoveHandler);
        // Listen for mouse releases
        document.addEventListener('mouseup', mouseUpHandler);
    }

    // Private method called to set up listeners related to the pointer lock
    #setPointerLockListeners()
    {
        // Lock changes event handler
        lockChangeHandler = () =>
        {
            // If the pointer is locked
            if(document.pointerLockElement === this.canvas || document.mozPointerLockElement === this.canvas)
            {
                // Set pointer status as locked
                this.locked = true;
                // Activate aim
                this.aim.style.display = '';
            }
            // If the pointer is unlocked
            else
            {
                // Set pointer status as unlocked
                this.locked = false;
                // Deactivate aim
                this.aim.style.display = 'none';
            }
        }
        
        // Listen for pointer changes
        document.addEventListener('pointerlockchange', lockChangeHandler);
        document.addEventListener('onmozpointerlockchange', lockChangeHandler);
    }

    // Private method called to set up the touch listeners
    #setTouchListeners()
    {
        // Touch event handler
        touchHandler = (e) =>
        {
            // If the scene is loaded
            if(this.experience.SCENE_LOADED === true)
            {
                // If this is the first click
                if(this.audio.firstClick === false)
                {
                    // Play audio if not muted
                    this.audio.firstClick = true;
                    if(this.audio.AUDIO_MUTED === false) this.audio.pauseOrResumeSoundtrack(0, true);
                }

                // If the click target is the canvas
                if(e.target === this.canvas)
                {
                    // If the touch started
                    if(e.type === "touchstart")
                    {
                        // Get mouse position
                        this.mouse.x = ((e.touches[0].clientX - this.sizes.marginLeft) / this.sizes.width) * 2 - 1;
                        this.mouse.y = -((e.touches[0].clientY  - this.sizes.marginTop) / this.sizes.height) * 2 + 1;
                    }
                    // If the touch moved
                    else if(e.type === "touchmove")
                    {
                        // Set verifier to true
                        pointerMoved = true;

                        // Get current position
                        const currentMousePos = new Vector2(((e.touches[0].clientX - this.sizes.marginLeft) / this.sizes.width) * 2 - 1, -((e.touches[0].clientY  - this.sizes.marginTop) / this.sizes.height) * 2 + 1);

                        // Get differencial between the start position and the current position
                        const dif = new Vector2(0, 0);
                        dif.copy(this.mouse);
                        dif.sub(currentMousePos);

                        // Move camera accordingly to the touch movement
                        this.camera.tpCamera.position.x -= dif.y * 10;
                        this.camera.tpCamera.position.y = 70;
                        this.camera.tpCamera.position.z -= dif.x * 10;

                        // Set limits to the camera vertically
                        if(this.camera.tpCamera.position.x < 64) this.camera.tpCamera.position.x = 64;
                        else if(this.camera.tpCamera.position.x > 72) this.camera.tpCamera.position.x = 72;
                        // Set limits to the camera horizontally
                        if(this.camera.tpCamera.position.z > 25) this.camera.tpCamera.position.z = 25;
                        else if(this.camera.tpCamera.position.z < -25) this.camera.tpCamera.position.z = -25;

                        // Get mouse position
                        this.mouse.x = ((e.touches[0].clientX - this.sizes.marginLeft) / this.sizes.width) * 2 - 1;
                        this.mouse.y = -((e.touches[0].clientY  - this.sizes.marginTop) / this.sizes.height) * 2 + 1;
                    }
                    // If the touch ended
                    else if(e.type === "touchend")
                    {
                        // If the touch didn't move
                        if(pointerMoved === false)
                        {
                            // If the user is touching a stand during the click
                            if(this.player.instance.hoveredStand !== null)
                            {
                                // Get touched stand
                                let hoveredStand = this.player.instance.hoveredStand;

                                // Trigger touch event
                                if(this.camera.FIRST_PERSON_CAM === false) this.trigger('pointerTouch');

                                // If the new touched stand is the same
                                if(hoveredStand === this.player.instance.hoveredStand)
                                {
                                    let data;

                                    // It the object intersected is a central totem button
                                    if(this.player.instance.hoveredStand.includes('pav'))
                                    {
                                        // Get section id
                                        const section = this.minimap.getSectionIdByDirection(this.player.instance.hoveredStand.split('_')[1]);
                                        
                                        // If there is a section in this direction
                                        if(section !== undefined)
                                        {
                                            const id = parseInt(section.split('_')[1]);

                                            // Data to be sent
                                            data = {
                                                'pavilion_id': this.pavilion.instance.info.pavilion_id,
                                                'current_section_id': this.pavilion.instance.id,
                                                'target_section_id': this.pavilion.instance.info.sections[id].section_id
                                            }

                                            // Trigger a click event on the respective minimap section
                                            this.minimap.instance.sections[id].click();
                                        }
                                        else
                                        {
                                            // Trigger event warning that the exit button in the totem was clicked
                                            const exitEvent = new CustomEvent( 'exitPavilion' );
                                            window.novvaC3.eventTarget.dispatchEvent(exitEvent);
                                        }
                                    }
                                    // It the object intersected is a stand
                                    else
                                    {
                                        // Get add factor
                                        let add = 0;
                                        if(this.player.instance.hoveredStand.includes('bullet')) add = 1;
                                        
                                        // Get the stand id
                                        const id = parseInt(this.player.instance.hoveredStand.split('_')[1 + add]);

                                        // Data to be sent
                                        data = {
                                            'pavilion_id': this.pavilion.instance.info.pavilion_id,
                                            'section_id': this.pavilion.instance.id,
                                            'stand_id': this.pavilion.instance.standsInfo.stands[id].stand_id,
                                            'player_position': this.player.instance.collider.end,
                                            'player_rotation': this.camera.fpCamera.rotation,
                                            'first_person_mode': this.camera.FIRST_PERSON_CAM
                                        }
                                    }

                                    // If the data isn't empty
                                    if(data !== undefined)
                                    {
                                        // Trigger event warning that this stand was clicked, sending the info acquired
                                        const standClickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
                                        window.novvaC3.eventTarget.dispatchEvent(standClickEvent);
                                    }
                                }
                            }
                            // If the user isn't touching a stand during the click
                            else
                            {
                                this.trigger('pointerTouch');
                            }
                        }
                        // If the touch moved
                        else
                        {
                            // Reset variable
                            pointerMoved = false;
                        }
                    }
                }
            }
        };

        // Listen for touches starting
        document.addEventListener('touchstart', touchHandler);
        // Listen for touches moving
        document.addEventListener('touchmove', touchHandler);
        // Listen for touches ending
        document.addEventListener('touchend', touchHandler);
    }

    // Method called to compare vectors and get if they are the same
    equals(firstValue, secValue, tolerance)
    {
        // If the tolerance factor isn't defined
        if(tolerance === undefined)
        {
            // Return literal sameness
            return ((firstValue.x === secValue.x) && (firstValue.y === secValue.y));
        }
        // If the tolerance factor is defined
        else
        {
            // Return if the vectors are the same within the tolerance
            return ((Math.abs(firstValue.x - secValue.x) < tolerance) && (Math.abs(firstValue.y - secValue.y) < tolerance));
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Remove reference
        this.mouse = null;

        // Deactivate listener
        this.resources.off('loadedResources');

        // Deactivate aim
        this.aim.style.display = 'none';
        this.aim = null;

        // If the device is a desktop
        if(isMobile === false)
        {
            // Stop listening for pointer lock changes
            document.removeEventListener('pointerlockchange', lockChangeHandler);
            document.removeEventListener('onmozpointerlockchange', lockChangeHandler);
            // Stop listening for mouse events
            document.removeEventListener('mousedown', mouseDownHandler);
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
            // Reset references
            lockChangeHandler = null;
            mouseDownHandler = null;
            mouseMoveHandler = null;
            mouseUpHandler = null;
        }
        // If the device is a mobile
        else
        {
            // Stop listening for touch events
            document.removeEventListener('touchstart', touchHandler);
            document.removeEventListener('touchmove', touchHandler);
            document.removeEventListener('touchend', touchHandler);
            // Reset reference
            touchHandler = null;
        }
        isMobile = null;

        // Remove references
        this.experience = null;
        this.sizes = null;
        this.audio = null;
        this.camera = null;
        this.canvas = null;
        this.pavilion = null;
        this.minimap = null;
        this.player = null;
    }
}