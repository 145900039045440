import { Vector3 } from "three";
import * as nipplejs from "nipplejs";

import Experience from "../Experience";
import EventEmitter from "./EventEmitter";

export default class Joystick extends EventEmitter
{
    // Set constructor
    constructor()
    {
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.keys = this.experience.keys;

        // Create the movement joystick
        this.#createMovementJoystick();
        // Create the camera joystick
        this.#createCameraJoystick();
        // Set listeners
        this.#setListeners();
    }

    // Private method called to create and set up the movement joystick
    #createMovementJoystick()
    {
        // Set instance
        this.movementJoystick = {};

        // Set base values
        this.movementJoystick.forwardValue = 0;
        this.movementJoystick.backwardValue = 0;
        this.movementJoystick.rightValue = 0;
        this.movementJoystick.leftValue = 0;
        this.movementJoystick.force;
        this.movementJoystick.movementVector = new Vector3();
        this.movementJoystick.container = document.getElementById('joystick-wrapper-movement');

        // Set joystick options
        const options = {
            zone: this.movementJoystick.container,
            size: 100,
            multitouch: true,
            maxNumberOfNipples: 2,
            mode: 'static',
            restJoystick: true,
            shape: 'circle',
            position: { bottom: '80px', left: '80px' },
            dynamicPage: true,
            color: '#202020'
        };

        // Create joystick with the selected options
        this.movementManager = nipplejs.create(options);

        // On joystick move
        this.movementManager['0'].on('move', (evt, data) =>
        {
            // Get camera class if still doesn't have it
            if(!this.camera) this.camera = this.experience.camera;

            // If the camera is on first person mode
            if(this.camera.FIRST_PERSON_CAM)
            {
                // Get movement values
                const forward = data.vector.y;
                const turn = data.vector.x;
                this.movementJoystick.force = data.force;
        
                // Set inputs
                this.#joystickInputs(this.movementJoystick, forward, turn);
            }
        });

        // On joystick stop moving
        this.movementManager['0'].on('end', (evt) =>
        {
            // Reset variables
            this.movementJoystick.backwardValue = 0;
            this.movementJoystick.forwardValue = 0;
            this.movementJoystick.leftValue = 0;
            this.movementJoystick.rightValue = 0;
        });

        // Display interface
        document.getElementById('player-mobile-interface').style.display = '';
    }

    // Private method called to create and set up the camera joystick
    #createCameraJoystick()
    {
        // Set instance
        this.cameraJoystick = {};

        // Set base values
        this.cameraJoystick.forwardValue = 0;
        this.cameraJoystick.backwardValue = 0;
        this.cameraJoystick.rightValue = 0;
        this.cameraJoystick.leftValue = 0;
        this.cameraJoystick.force;
        this.cameraJoystick.movementVector = new Vector3();
        this.cameraJoystick.container = document.getElementById('joystick-wrapper-camera');

        // Set joystick options
        const options = {
            zone: this.cameraJoystick.container,
            size: 100,
            multitouch: true,
            maxNumberOfNipples: 2,
            mode: 'static',
            restJoystick: true,
            shape: 'circle',
            position: { bottom: '80px', right: '80px' },
            dynamicPage: true,
            color: '#202020'
        };

        // Create joystick with the selected options
        this.cameraManager = nipplejs.create(options);

        // On joystick move
        this.cameraManager['0'].on('move', (evt, data) =>
        {
            // Get camera class if still doesn't have it
            if(!this.camera) this.camera = this.experience.camera;

            // If the camera is on first person mode
            if(this.camera.FIRST_PERSON_CAM)
            {
                // Get movement values
                const forward = data.vector.y;
                const turn = data.vector.x;
                this.cameraJoystick.force = data.force;
        
                // Set inputs
                this.#joystickInputs(this.cameraJoystick, forward, turn);
            }
        });

        // On joystick stop moving
        this.cameraManager['0'].on('end', (evt) =>
        {
            // Reset variables
            this.cameraJoystick.backwardValue = 0;
            this.cameraJoystick.forwardValue = 0;
            this.cameraJoystick.leftValue = 0;
            this.cameraJoystick.rightValue = 0;
        });
    }

    // Private method called to set the joystick inputs
    #joystickInputs(joystick, forward, turn)
    {
        // If the joystick is moving forwards
        if(forward > 0)
        {
            joystick.forwardValue = Math.abs(forward);
            joystick.backwardValue = 0;
        }
        // If the joystick is moving backwards
        else if(forward < 0)
        {
            joystick.forwardValue = 0;
            joystick.backwardValue = Math.abs(forward);
        }

        // If the joystick is moving to the right
        if(turn > 0)
        {
            joystick.leftValue = 0;
            joystick.rightValue = Math.abs(turn);
        }
        // If the joystick is moving to the left
        else if(turn < 0)
        {
            joystick.leftValue = Math.abs(turn);
            joystick.rightValue = 0;
        }
    }

    // Private method called to set up the listeners
    #setListeners()
    {
        // Listen for switch camera event
        this.keys.on('switchCamera', () =>
        {
            // Get camera class if still doesn't have it
            if(!this.camera) this.camera = this.experience.camera;

            // If the camera is switching from the first to the third person mode
            if(this.camera.FIRST_PERSON_CAM)
            {
                // Don't display joysticks
                this.movementJoystick.container.style.display = 'none';
                this.cameraJoystick.container.style.display = 'none';
            }
            // If the camera is switching from the third to the first person mode
            else
            {
                // Display joysticks
                this.movementJoystick.container.style.display = '';
                this.cameraJoystick.container.style.display = '';
            }
        });
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Stop listening for switch camera event
        this.keys.off('switchCamera');

        // Destroy joystick managers
        this.movementManager.destroy();
        this.cameraManager.destroy();

        // Reset joysticks
        this.movementJoystick = null;
        this.cameraJoystick = null;

        // Remove references
        this.experience = null;
        this.keys = null;
        this.camera = null;
    }
}