import * as THREE from "three";

import Experience from "./Experience";
import EventEmitter from "./Utils/EventEmitter";

let allowFootsteps;

export default class Audio extends EventEmitter
{
    // Set constructor
    constructor(AUDIO_MUTED)
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.resources = this.experience.resources;

        allowFootsteps = true;

        // Set audios object
        this.audios = {};
        this.firstClick = false;
        this.volume = [0.1, 0.4, 0.3];

        // Audio muted at the start
        this.AUDIO_MUTED = AUDIO_MUTED;

        // Set audio listener
        this.listener = new THREE.AudioListener();

        // Listen to when the resources are loaded
        this.resources.on('loadedResources', () =>
        {
            // Set the audio listener
            this.#setAudioListener();
            // Set ambiance sound
            this.#setAmbiance();
        });
    }

    // Private method called to set up the audio listener
    #setAudioListener()
    {
        // Get the camera class from the experience
        this.camera = this.experience.camera;
        // Add to the camera
        this.camera.renderCamera.add(this.listener);
        // Remove reference
        this.camera = null;
    }

    // Private method called to set up the ambiance sound
    #setAmbiance()
    {
        // If the soundtrack hasn't been loaded yet
        if(this.audios.bg_soundtrack === undefined)
        {
            // Create audio
            this.audios.bg_soundtrack = new THREE.Audio(this.listener);
            // Get soundtrack from the resources
            this.audios.bg_soundtrack.setBuffer(this.resources.items.soundtrack_0);
            // Set loop and volume
            this.audios.bg_soundtrack.setLoop(true);
            this.audios.bg_soundtrack.setVolume(this.volume[0]);
        }
        
        // If the background track hasn't been loaded yet
        if(this.audios.bg_chatter === undefined)
        {
            // Create audio
            this.audios.bg_chatter = new THREE.Audio(this.listener);
            // Get chatter sound from the resources
            this.audios.bg_chatter.setBuffer(this.resources.items.gallery_chatter);
            // Set loop and volume
            this.audios.bg_chatter.setLoop(true);
            this.audios.bg_chatter.setVolume(this.volume[1]);
        }
    }

    // Method called to change the audio track
    changeAudioTrack(id, url)
    {
        // Load the section model
        this.resources.loaders.audioLoader.load( url,
            // OnLoad callback
            (buffer) =>
            {
                // If the soundtrack is being changed
                if(id === 0)
                {
                    // If the soundtrack is already loaded
                    if(this.audios.bg_soundtrack !== undefined)
                    {
                        // If the audio is playing, stop it before the change
                        const playing = this.audios.bg_soundtrack.isPlaying;
                        if(playing === true) this.audios.bg_soundtrack.stop();

                        // Set new buffer and volume
                        this.audios.bg_soundtrack.setBuffer(buffer);
                        this.audios.bg_soundtrack.setVolume(this.volume[0]);

                        // If the audio was playing before the change, play it after done
                        if(playing === true) this.audios.bg_soundtrack.play();
                    }
                    // If the soundtrack hasn't been loaded yet
                    else
                    {
                        // Create audio
                        this.audios.bg_soundtrack = new THREE.Audio(this.listener);
                        // Get soundtrack from the resources
                        this.audios.bg_soundtrack.setBuffer(buffer);
                        // Set loop and volume
                        this.audios.bg_soundtrack.setLoop(true);
                        this.audios.bg_soundtrack.setVolume(this.volume[0]);
                    }
                }
                // If the background track is being changed
                else if(id === 1)
                {
                    // If the background track is already loaded
                    if(this.audios.bg_chatter !== undefined)
                    {
                        // If the audio is playing, stop it before the change
                        const playing = this.audios.bg_chatter.isPlaying;
                        if(playing === true) this.audios.bg_chatter.stop();

                        // Set new buffer and volume
                        this.audios.bg_chatter.setBuffer(buffer);
                        this.audios.bg_chatter.setVolume(this.volume[1]);

                        // If the audio was playing before the change, play it after done
                        if(playing === true) this.audios.bg_chatter.play();
                    }
                    // If the background track hasn't been loaded yet
                    else
                    {
                        // Create audio
                        this.audios.bg_chatter = new THREE.Audio(this.listener);
                        // Get soundtrack from the resources
                        this.audios.bg_chatter.setBuffer(buffer);
                        // Set loop and volume
                        this.audios.bg_chatter.setLoop(true);
                        this.audios.bg_chatter.setVolume(this.volume[1]);
                    }
                }
            },
            // OnProgress callback
            () => {},
            // OnError callback
            (error) => 
            {
                console.log("An error happened while loading the new audio track. See log below.");
                console.log(error);
            }
        );
    }

    // Private method called to set up the footstep audios
    createSetOfFootsteps(footsteps)
    {
        // For each of the footstep audios
        for(let i = 0; i < 4; i++)
        {
            // Create audio
            const audio = new THREE.Audio(this.listener);
            
            // Load all footstep audios
            this.resources.loaders.audioLoader.load(
                // Asset adress
                this.resources.ASSETS_PATH + 'audio/effects/footstep_' + i + '.mp3',
                // Finished loading
                (buffer) =>
                {
                    // Set buffer and volume
                    audio.setBuffer(buffer);
                    audio.setVolume(this.volume[2]);

                    // Add to the array
                    footsteps.push(audio);
                }
            );
        }
    }

    // Method called to play a random audio from the footstep audios array
    playFootstep(footsteps, volume)
    {
        // If the footsteps effects are allowed
        if(allowFootsteps === true)
        {
            // If the footsteps array is valid
            if(footsteps)
            {
                // Set min value
                const min = Math.ceil(0);
                // Set max value
                const max = Math.floor(3);

                // Get random number between min and max
                const id = Math.floor(Math.random() * (max - min + 1)) + min;

                footsteps[id].setVolume(volume);
                // Play the footstep audio
                footsteps[id].play();
            }
        }
    }

    // Method called to pause or resume the ambient song
    pauseOrResumeSoundtrack(id, bool)
    {
        // Set default value to 0
        if(id != 0 && id != 1 && id != 2) id = 0;

        // If only the footsteps were selected
        if(id == 3)
        {
            // Set if the footsteps can be played or not
            if(bool === true || bool === false) allowFootsteps = bool;
            else allowFootsteps = !allowFootsteps;
        }
        // If another soundtracks were selected
        else
        {
            if(id == 1 || id == 0)
            {
                // If bool is undefined, define if the soundtrack should be paused or resumed
                if(bool === null || bool === undefined) bool = !this.audios.bg_soundtrack.isPlaying;

                // Resume the soundtrack
                if(bool === true) this.audios.bg_soundtrack.play();
                // Pause the soundtrack
                else this.audios.bg_soundtrack.pause();
            }
            
            if(id == 2 || id == 0)
            {
                if(id == 2)
                {
                    // If bool is undefined, define if the soundtrack should be paused or resumed
                    if(bool === null || bool === undefined) bool = !this.audios.bg_chatter.isPlaying;
                }
                else
                {
                    // If bool is undefined, define if the soundtrack should be paused or resumed
                    if(bool === null || bool === undefined) bool = !this.audios.bg_soundtrack.isPlaying;
                    // Set if the footsteps can be played or not
                    allowFootsteps = bool;
                }

                // Resume the soundtrack
                if(bool === true) this.audios.bg_chatter.play();
                // Pause the soundtrack
                else this.audios.bg_chatter.pause();
            }
        }
    }

    // Method called to set the ambient song volume
    setSoundtrackVolume(id, value)
    {
        // If the value isn't a number
        if(isNaN(value) || value === "")
        {
            console.log("Volume set to the soundtrack is not a number: " + value);
        }
        // If the value is a number
        else
        {
            // Set min value as 0, max value as 1
            value = Math.min(Math.abs(value), 1);

            if(id === 1)
            {
                // If the audio haven't been loaded yet
                if(this.audios.bg_soundtrack === undefined)
                {
                    // Save volume for later
                    this.volume[0] = value;
                }
                // If the audio is loaded
                else
                {
                    // Set audio volume
                    this.volume[0] = value;
                    this.audios.bg_soundtrack.setVolume(this.volume[0]);
                }
            }
            else if(id === 2)
            {
                // If the audio haven't been loaded yet
                if(this.audios.bg_chatter === undefined)
                {
                    // Save volume for later
                    this.volume[1] = value;
                }
                // If the audio is loaded
                else
                {
                    // Set audio volume
                    this.volume[1] = value;
                    this.audios.bg_chatter.setVolume(this.volume[1]);
                }
            }
            else
            {
                // If the audio haven't been loaded yet
                if(this.audios.bg_soundtrack === undefined)
                {
                    // Save volume for later
                    this.volume[0] = value;
                }
                // If the audio is loaded
                else
                {
                    // Set audio volume
                    this.volume[0] = value;
                    this.audios.bg_soundtrack.setVolume(this.volume[0]);
                }

                // If the audio haven't been loaded yet
                if(this.audios.bg_chatter === undefined)
                {
                    // Save volume for later
                    this.volume[1] = value;
                }
                // If the audio is loaded
                else
                {
                    // Set audio volume
                    this.volume[1] = value;
                    this.audios.bg_chatter.setVolume(this.volume[1]);
                }
            }
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Stop listening for the loaded resources event
        this.resources.off('loadedResources');

        // Stop soundtrack
        if(this.audios.bg_soundtrack.isPlaying) this.audios.bg_soundtrack.stop();
        if(this.audios.bg_chatter.isPlaying) this.audios.bg_chatter.stop();

        // Reset variables
        allowFootsteps = null;
        this.audios = null;
        this.listener = null;

        // Remove references
        this.experience = null;
        this.resources = null;
    }
}