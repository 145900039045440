import { Group } from "three";
import { Octree } from 'three/examples/jsm/math/Octree.js';

export default class Colliders
{
    // Set constructor
    constructor()
    {
        // Create octree colliders
        this.octree = new Octree();
        this.octreeColliders = new Group();
    }

    // Method called to add a new collider to the octree array
    addCollider(object)
    {
        this.octreeColliders.add(object);
    }

    // Method called to generate the octree colliders from the array
    generateOctreeColliders()
    {
        // Create new octree
        this.octree = new Octree({
            undeferred: true,
            depthMax: Infinity,
            overlapPct: 0.15
        });

        // If the octree colliders group have children
        if(this.octreeColliders.children.length > 0)
        {
            // Generate octree colliders
            this.octree.fromGraphNode(this.octreeColliders);
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Clear the array
        this.octreeColliders.clear();

        // Set octree to null
        this.octree = null;
    }
}