export default [
    {
        name: 'environmentBackground',
        type: 'cubeTexture',
        path:
        [
            'environment/cub_px.png',
            'environment/cub_nx.png',
            'environment/cub_py.png',
            'environment/cub_ny.png',
            'environment/cub_pz.png',
            'environment/cub_nz.png'
        ]
    },
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'environment/envMap/test_px.png',
            'environment/envMap/test_nx.png',
            'environment/envMap/test_py.png',
            'environment/envMap/test_ny.png',
            'environment/envMap/test_pz.png',
            'environment/envMap/test_nz.png'
        ]
    },
    {
        name: 'section_walls',
        type: 'gltfModel',
        path: 'models/sections/section_walls/section_walls.gltf'
    },
    {
        name: 'stand_s0',
        type: 'gltfModel',
        path: 'models/stands/stand_s0/stand_s0.gltf'
    },
    {
        name: 'stand_s1',
        type: 'gltfModel',
        path: 'models/stands/stand_s1/stand_s1.gltf'
    },
    {
        name: 'stand_s2',
        type: 'gltfModel',
        path: 'models/stands/stand_s2/stand_s2.gltf'
    },
    {
        name: 'stand_g0',
        type: 'gltfModel',
        path: 'models/stands/stand_g0/stand_g0.gltf'
    },
    {
        name: 'stand_g1',
        type: 'gltfModel',
        path: 'models/stands/stand_g1/stand_g1.gltf'
    },
    {
        name: 'stand_g2',
        type: 'gltfModel',
        path: 'models/stands/stand_g2/stand_g2.gltf'
    },
    {
        name: 'stand_g3',
        type: 'gltfModel',
        path: 'models/stands/stand_g3/stand_g3.gltf'
    },
    {
        name: 'npc_0',
        type: 'gltfModel',
        path: 'models/npcs/npc_0/npc_0.gltf'
    },
    {
        name: 'npc_1',
        type: 'gltfModel',
        path: 'models/npcs/npc_1/npc_1.gltf'
    },
    {
        name: 'npc_2',
        type: 'gltfModel',
        path: 'models/npcs/npc_2/npc_2.gltf'
    },
    {
        name: 'npc_3',
        type: 'gltfModel',
        path: 'models/npcs/npc_3/npc_3.gltf'
    },
    {
        name: 'npc_4',
        type: 'gltfModel',
        path: 'models/npcs/npc_4/npc_4.gltf'
    },
    {
        name: 'npc_5',
        type: 'gltfModel',
        path: 'models/npcs/npc_5/npc_5.gltf'
    },
    {
        name: 'npc_6',
        type: 'gltfModel',
        path: 'models/npcs/npc_6/npc_6.gltf'
    },
    {
        name: 'npc_7',
        type: 'gltfModel',
        path: 'models/npcs/npc_7/npc_7.gltf'
    },
    {
        name: 'npc_8',
        type: 'gltfModel',
        path: 'models/npcs/npc_8/npc_8.gltf'
    },
    {
        name: 'aim',
        type: 'texture',
        path: 'images/icons/aim.png'
    },
    {
        name: 'playButton',
        type: 'texture',
        path: 'images/icons/playButton.png'
    },
    {
        name: 'gallery_chatter',
        type: 'audio',
        path: 'audio/effects/gallery_chatter.mp3'
    },
    {
        name: 'soundtrack_0',
        type: 'audio',
        path: 'audio/songs/the-weekend.mp3'
    },
    {
        name: 'bullet',
        type: 'texture',
        path: 'icons/bullet.png'
    }
]