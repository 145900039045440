import { Raycaster, Vector3, Vector2, Euler } from "three";
import { Capsule } from 'three/examples/jsm/math/Capsule.js';
import gsap from 'gsap';

import Experience from "../Experience.js";
import EventEmitter from '../Utils/EventEmitter.js';

let isMobile, animateBullet;

export default class Player extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.audio = this.experience.audio;
        this.pointer = this.experience.pointer;
        this.time = this.experience.time;
        this.camera = this.experience.camera;
        this.composer = this.experience.composer;
        this.colliders = this.experience.colliders;
        this.minimap = this.experience.minimap;

        // Default value
        animateBullet = false;

        // Create raycast
        this.raycaster = new Raycaster();

        // Call method to create the player
        this.#setPlayer();
    }

    // Private method called to create the player instance
    #setPlayer()
    {
        // Create new instance
        this.instance = {};

        // Create player collider
        this.instance.collider = new Capsule(new Vector3(this.camera.fpCamera.position.x, 0.35, this.camera.fpCamera.position.z), new Vector3(this.camera.fpCamera.position.x, 1.6, this.camera.fpCamera.position.z), 0.75 );

        // Create velocity and direction vectors
        this.instance.velocity = new Vector3();
        this.instance.direction = new Vector3();
        // Movement variables
        this.instance.stepsPerFrame = 5;
        this.instance.moving = false;
        // Hovered stand
        this.instance.hoveredStand = null;

        // Set footsteps manager
        this.footstepsManager = {
            audios: [],
            volume: 0.3,
            playing: false
        };
        // Set a local set of footstep audios
        this.audio.createSetOfFootsteps(this.footstepsManager.audios);

        // Get the mobile detector class from the experience
        this.mobileDetector = this.experience.mobileDetector;
        // Verify if the device is a mobile
        isMobile = this.mobileDetector.isMobile;
        // Remove reference
        this.mobileDetector = null;

        // If the device is a mobile
        if(isMobile === true)
        {
            // Get the joystick class from the experience
            this.joystick = this.experience.joystick;

            // Listen for touch event
            this.pointer.on('pointerTouch', () =>
            {
                // Cast a raycast
                this.#castRaycast(this.pointer.mouse);
            });
        }
        // If the device is a desktop
        else
        {
            // Get the keys class from the experience
            this.keys = this.experience.keys;
        }

        // Get the pavilion class from the experience
        this.pavilion = this.experience.pavilion;
        // Listen to when the stands are ready
        this.pavilion.on('standsReady', () =>
        {
            // Get the stand models from the pavilion class
            this.stands = this.pavilion.instance.standModels;

            // Get all the central totem buttons
            this.pavilion.walls.totemColors.forEach(button =>
            {
                // Add to the interactables array
                this.stands.push(button);
            });
        });
    }

    // Method called to set the player position and rotation
    setPosition(pos, rot)
    {
        // If the position is a Vector3
        if(pos instanceof Vector3)
        {
            // Set position
            this.instance.collider.set(new Vector3(pos.x, 0.35, pos.z), new Vector3(pos.x, 1.6, pos.z), 0.75);
        }
        // If the position is an array
        else if(pos instanceof Array && pos.length == 3)
        {
            // Set position
            this.instance.collider.set(new Vector3(pos[0], 0.35, pos[2]), new Vector3(pos[0], 1.6, pos[2]), 0.75);
        }
        // If the position is invalid
        else
        {
            // Set default position
            this.instance.collider.set(new Vector3(45, 0.35, 0), new Vector3(45, 1.6, 0), 0.75);
        }

        // Set camera position
        this.camera.fpCamera.position.copy(this.instance.collider.end);

        // If the rotation is an Euler or a Vector3
        if(rot instanceof Euler || rot instanceof Vector3)
        {
            // Set rotation
            this.camera.fpCamera.rotation.set(rot.x, rot.y, rot.z);
        }
        // If the rotation is an array
        else if(rot instanceof Array && rot.length == 3)
        {
            // Set rotation
            this.camera.fpCamera.rotation.set(rot[0], rot[1], rot[2]);
        }
        // If the rotation is invalid
        else
        {
            // Set default rotation
            this.camera.fpCamera.lookAt(0, 1.5, 0);
        }
    }

    // Private method called to get the forward vector from the player
    #getForwardVector()
    {
        // Get normalized direction
        this.camera.fpCamera.getWorldDirection(this.instance.direction);
        this.instance.direction.normalize();

        return this.instance.direction;
    }

    // Private method called to get the right side vector from the player
    #getSideVector()
    {
        // Get normalized direction
        this.camera.fpCamera.getWorldDirection(this.instance.direction);
        // Fix the y direction
        this.instance.direction.y = 0;
        this.instance.direction.normalize();
        this.instance.direction.cross(this.camera.fpCamera.up);

        return this.instance.direction;
    }

    // Private method called to react to the key states
    #updateKeyStates(deltaTime)
    {
        // Movement damping
        const speedDelta = deltaTime * 30;

        // Move forwards
        if(this.keys.keyStates['KeyW'])
        {
            this.instance.moving = true;
            this.instance.velocity.add(this.#getForwardVector().multiplyScalar(speedDelta));
        }
        // Move backwards
        if (this.keys.keyStates['KeyS'])
        {
            this.instance.moving = true;
            this.instance.velocity.add(this.#getForwardVector().multiplyScalar(-speedDelta));
        }
        // Move to the left
        if(this.keys.keyStates['KeyA'])
        {
            this.instance.moving = true;
            this.instance.velocity.add(this.#getSideVector().multiplyScalar(-speedDelta));
        }
        // Move to the right
        if(this.keys.keyStates['KeyD'])
        {
            this.instance.moving = true;
            this.instance.velocity.add(this.#getSideVector().multiplyScalar(speedDelta));
        }
    }

    // Private method called to react to the key states
    #updateJoystickValues(deltaTime)
    {
        // Movement damping
        const speedDelta = deltaTime * 20 * Math.min(1, this.joystick.movementJoystick.force);

        // Move forwards
        if(this.joystick.movementJoystick.forwardValue >= 0.25)
        {
            this.instance.moving = true;
            this.instance.velocity.add(this.#getForwardVector().multiplyScalar(speedDelta));
        }
        // Move backwards
        if(this.joystick.movementJoystick.backwardValue >= 0.25)
        {
            this.instance.moving = true;
            this.instance.velocity.add(this.#getForwardVector().multiplyScalar(-speedDelta));
        }
        // Move to the left
        if(this.joystick.movementJoystick.leftValue >= 0.25)
        {
            this.instance.moving = true;
            this.instance.velocity.add(this.#getSideVector().multiplyScalar(-speedDelta));
        }
        // Move to the right
        if(this.joystick.movementJoystick.rightValue >= 0.25)
        {
            this.instance.moving = true;
            this.instance.velocity.add(this.#getSideVector().multiplyScalar(speedDelta));
        }

        // Rotate forwards
        if(this.joystick.cameraJoystick.forwardValue >= 0.25)
        {
            this.camera.fpCamera.rotation.x += Math.min(1, this.joystick.cameraJoystick.forwardValue) / 200;
            // Add limits to the camera rotation
            if(this.camera.fpCamera.rotation.x > 0.65) this.camera.fpCamera.rotation.x = 0.65;
            
        }
        // Rotate backwards
        if(this.joystick.cameraJoystick.backwardValue >= 0.25)
        {
            this.camera.fpCamera.rotation.x -= Math.min(1, this.joystick.cameraJoystick.backwardValue) / 200;
            // Add limits to the camera rotation
            if(this.camera.fpCamera.rotation.x < -0.7) this.camera.fpCamera.rotation.x = -0.7;
        }
        // Rotate to the left
        if(this.joystick.cameraJoystick.leftValue >= 0.25)
        {
            this.camera.fpCamera.rotation.y += Math.min(1, this.joystick.cameraJoystick.leftValue) / 100;
        }
        // Rotate to the right
        if(this.joystick.cameraJoystick.rightValue >= 0.25)
        {
            this.camera.fpCamera.rotation.y -= Math.min(1, this.joystick.cameraJoystick.rightValue) / 100;
        }
    }

    // Private method called to get the player collisions
    #playerCollisions()
    {
        if(this.colliders.octree !== null && this.colliders.octree !== undefined)
        {
            // Get the intersections between the player collider and the world collider
            const result = this.colliders.octree.capsuleIntersect(this.instance.collider);

            if(result)
            {
                // Don't allow the player to trespass the world colliders
                this.instance.collider.translate(result.normal.multiplyScalar(result.depth));
            }
        }
    }

    // Private method called to update the player
    #updatePlayer(deltaTime)
    {
        // Movement damping
        let damping = Math.exp(-4 * deltaTime) - 1;

        // Set player velocity
        this.instance.velocity.addScaledVector(this.instance.velocity, damping);

        // Update player position
        const deltaPosition = this.instance.velocity.clone().multiplyScalar(deltaTime);
        this.instance.collider.translate(deltaPosition);
        this.instance.collider.end.y = 1.5;

        // Check for collisions
        this.#playerCollisions();

        // Update the camera position
        this.camera.fpCamera.position.copy(this.instance.collider.end);
    }

    // Private method called to set the footstep playing time
    #playFootstepsAudio()
    {
        // If the footstep timer isn't counting
        if(this.footstepsManager.playing === false)
        {
            // Start footstep counting
            this.footstepsManager.playing = true;
            this.instance.moving = false;

            // Call audio manager to play the footstep audio
            this.audio.playFootstep(this.footstepsManager.audios, this.footstepsManager.volume);

            // Set time out
            setTimeout(() =>
            {
                // Signal the footstep count ending
                if(this.footstepsManager) this.footstepsManager.playing = false;
            }, 650);
        }
    }

    // Private method called to find the stand object from the hovered child
    #getIntersectionParent(object)
    {
        // Initialize variables
        let parentObject = object.parent;
        let gotRightParent = false;

        // Loop until the stand parent is found
        do
        {
            // If the parent object has a name
            if(parentObject.name !== undefined)
            {
                // Get parent name
                let parentName = parentObject.name.split('_')[0];

                // Get parent
                if(parentName != 'stand' && parentName != 'section')
                {
                    let furtherParent = parentObject.parent;
                    parentObject = furtherParent;
                }
                // Finish the loop
                else gotRightParent = true;
            }
            // If the parent object doesn't has a name
            else
            {
                // Get parent
                let furtherParent = parentObject.parent;
                parentObject = furtherParent;
            }

        } while(gotRightParent === false);

        // Return parent found
        return parentObject;
    }

    // Private method called to cast a new raycast
    #castRaycast(coords)
    {
        // If the stands are loaded
        if(this.stands !== undefined)
        {
            // Get interactives
            let interactives = this.stands.concat([this.pavilion.instance.model]);
            if(this.camera.FIRST_PERSON_CAM === false) interactives = interactives.concat(this.pavilion.instance.bullets);

            // Set raycaster
            this.raycaster.setFromCamera(coords, this.camera.renderCamera);
            const intersection = this.raycaster.intersectObjects(interactives);
            
            // If the raycaster have intersected with the stands
            if(intersection.length > 0)
            {
                // If it's intersecting with a central totem button
                if(intersection[0].object.name.includes('pav'))
                {
                    // Verify if the object is in range
                    if(this.camera.FIRST_PERSON_CAM && intersection[0].distance <= 5)
                    {
                        // If the user hovered a new stand
                        if(this.instance.hoveredStand != intersection[0].object.name)
                        {
                            // Update the outline pass objects
                            this.instance.hoveredStand = intersection[0].object.name;
                            // Trigger hover event
                            this.trigger('hoveringStand');
                        }
                    }
                    // If the object isn't in range
                    else
                    {
                        if(this.instance.hoveredStand !== null)
                        {
                            // Trigger hover event as ended
                            this.trigger('stoppedHoveringStand');

                            // Reset variable
                            this.instance.hoveredStand = null;
                        }
                    }
                }
                // If it's intersecting with a stand
                else
                {
                    // Verify if the object is in range of a desktop device
                    const inRange = (this.camera.FIRST_PERSON_CAM && intersection[0].distance <= 20) || (!this.camera.FIRST_PERSON_CAM);
                    // If the object is in range if the device is a desktop, or if the device is a mobile
                    if(inRange === true)
                    {
                        // Get the intersected object
                        let parent = intersection[0].object;
                        // If the intersected object isn't a bullet
                        if(parent.name.includes('bullet') === false)
                        {
                            // Get the object parent
                            parent = this.#getIntersectionParent(intersection[0].object);
                        }
                        // If the intersected object is a bullet and it's not animated
                        else if(animateBullet === false)
                        {
                            // Set as animated
                            animateBullet = true;

                            // Tween bullet scale and opacity
                            gsap.to(parent.scale, { x: 1.5, y: 1.5, duration: 0.2 });
                            gsap.to(parent.material, { opacity: 1, duration: 0.2 });
                        }

                        // If the parent object isn't the section model
                        if(parent.name.includes('section') === false)
                        {
                            // If the user hovered a new stand
                            if(this.instance.hoveredStand != parent.name)
                            {
                                // If the bullet is animated
                                if(animateBullet === true)
                                {
                                    // For each of the bullets
                                    for(let i = 0; i < this.pavilion.instance.bullets.length; i++)
                                    {
                                        // If the correct bullet was found
                                        if(this.pavilion.instance.bullets[i].name.includes(this.instance.hoveredStand))
                                        {
                                            // Tween bullet scale and opacity
                                            gsap.to(this.pavilion.instance.bullets[i].scale, { x: 1, y: 1, duration: 0.2 });
                                            gsap.to(this.pavilion.instance.bullets[i].material, { opacity: 0.8, duration: 0.2 });
                                            
                                            break;
                                        }
                                    }
                                }

                                // Update the outline pass objects
                                this.instance.hoveredStand = parent.name;
                                // Trigger hover event
                                this.trigger('hoveringStand');

                                // Update outlined objects
                                if(parent.name.includes('bullet') === false) this.composer.updateOutlineObjects(parent);
                                else
                                {
                                    // Tween bullet scale and opacity
                                    gsap.to(parent.scale, { x: 1.5, y: 1.5, duration: 0.2 });
                                    gsap.to(parent.material, { opacity: 1, duration: 0.2 });
                                }
                            }
                        }
                        // If the parent object is the section model
                        else
                        {
                            // If the variable isn't null
                            if(this.instance.hoveredStand !== null)
                            {
                                this.trigger('stoppedHoveringStand');

                                // If the bullet is animated
                                if(animateBullet === true)
                                {
                                    // For each of the bullets
                                    for(let i = 0; i < this.pavilion.instance.bullets.length; i++)
                                    {
                                        // If the correct bullet was found
                                        if(this.pavilion.instance.bullets[i].name.includes(this.instance.hoveredStand))
                                        {
                                            // Set as not animated
                                            animateBullet = false;

                                            // Tween bullet scale and opacity
                                            gsap.to(this.pavilion.instance.bullets[i].scale, { x: 1, y: 1, duration: 0.2 });
                                            gsap.to(this.pavilion.instance.bullets[i].material, { opacity: 0.8, duration: 0.2 });
                                            
                                            break;
                                        }
                                    }
                                }

                                // Reset outline objects
                                this.instance.hoveredStand = null;
                                // Remove outline from objects
                                this.composer.updateOutlineObjects(null);
                            }
                        }
                    }
                    // If the user is not close enough to a stand
                    else if(inRange === false)
                    {
                        if(this.instance.hoveredStand !== null)
                        {
                            // Trigger hover event as ended
                            this.trigger('stoppedHoveringStand');

                            // If the bullet is animated
                            if(animateBullet === true)
                            {
                                // For each of the bullets
                                for(let i = 0; i < this.pavilion.instance.bullets.length; i++)
                                {
                                    // If the correct bullet was found
                                    if(this.pavilion.instance.bullets[i].name.includes(this.instance.hoveredStand))
                                    {
                                        // Set as not animated
                                        animateBullet = false;

                                        // Tween bullet scale and opacity
                                        gsap.to(this.pavilion.instance.bullets[i].scale, { x: 1, y: 1, duration: 0.2 });
                                        gsap.to(this.pavilion.instance.bullets[i].material, { opacity: 0.8, duration: 0.2 });
                                        
                                        break;
                                    }
                                }
                            }

                            // Reset outline objects
                            this.instance.hoveredStand = null;
                            // Update outlined objects
                            this.composer.updateOutlineObjects(null);
                        }
                    }
                }
            }
            // If the user is not hovering anything
            else
            {
                // If the variable isn't null
                if(this.instance.hoveredStand !== null)
                {
                    this.trigger('stoppedHoveringStand');

                    // If the bullet is animated
                    if(animateBullet === true)
                    {
                        // For each of the bullets
                        for(let i = 0; i < this.pavilion.instance.bullets.length; i++)
                        {
                            // If the correct bullet was found
                            if(this.pavilion.instance.bullets[i].name.includes(this.instance.hoveredStand))
                            {
                                // Set as not animated
                                animateBullet = false;

                                // Tween bullet scale and opacity
                                gsap.to(this.pavilion.instance.bullets[i].scale, { x: 1, y: 1, duration: 0.2 });
                                gsap.to(this.pavilion.instance.bullets[i].material, { opacity: 0.8, duration: 0.2 });
                                
                                break;
                            }
                        }
                    }

                    // Reset outline objects
                    this.instance.hoveredStand = null;
                    // Remove outline from objects
                    this.composer.updateOutlineObjects(null);
                }
            }
        }
    }

    // Private method called to update the raycast
    #updateRaycast()
    {
        // If the device is a mobile
        if(isMobile === false)
        {
            // If one of the camera modes is correctly active
            if((this.camera.FIRST_PERSON_CAM && this.pointer.locked) || (!this.camera.FIRST_PERSON_CAM))
            {
                // If the player is moving
                if(this.pointer.mouseMove || this.instance.moving)
                {
                    this.pointer.mouseMove = false;
                    this.instance.moving = false;

                    // Set raycast origin to the center of the screen
                    let raycastCoords = new Vector2(0, 0);
                    if(!this.camera.FIRST_PERSON_CAM)
                    {
                        // Set raycast origin to the mouse position
                        raycastCoords = this.pointer.mouse;
                    }

                    // Cast a raycast
                    this.#castRaycast(raycastCoords);
                }
            }
        }
        // If the device is a desktop
        else
        {
            // If the first person camera is active
            if(this.camera.FIRST_PERSON_CAM)
            {
                // Cast a raycast
                this.#castRaycast(new Vector2(0, 0));
            }
        }
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Get the delta time
        const deltaTime = Math.min( 0.05, this.time.delta ) / this.instance.stepsPerFrame;

        // Look for collisions in substeps to mitigate the risk of an object traversing another too quickly for detection
        for(let i = 0; i < this.instance.stepsPerFrame; i ++)
        {
            // If the first person is active
            if(this.camera.FIRST_PERSON_CAM)
            {
                // If the device is a desktop
                if(isMobile === false && this.pointer.locked === true)
                {
                    // Update movement keys
                    this.#updateKeyStates(deltaTime);
                }
                // If the device is a mobile
                else if(isMobile === true)
                {
                    // Update joystick values
                    this.#updateJoystickValues(deltaTime);
                }
                // Update the player
                this.#updatePlayer(deltaTime);
            }
        }

        // If the player is moving
        if(this.instance.moving === true)
        {
            // Play footstep audio
            this.#playFootstepsAudio();
        }

        // Update the raycast
        this.#updateRaycast();
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Remove listener
        this.pavilion.off('standsReady');

        // If the device is a mobile
        if(isMobile === true)
        {
            this.joystick = null;
            // Listen for touch event
            this.pointer.off('pointerTouch');
        }
        // If the device is a desktop
        else this.keys = null;

        // Delete array of stands
        this.stands.length = 0;
        this.stands = undefined;

        // Reset instance
        this.instance = null;
        this.raycaster = null;
        this.footstepsManager.audios.length = 0;
        this.footstepsManager = null;

        // Remove references
        this.experience = null;
        this.audio = null;
        this.pointer = null;
        this.time = null;
        this.camera = null;
        this.composer = null;
        this.colliders = null;
        this.pavilion = null;
        this.minimap = null;
    }
}