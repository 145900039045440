import { Vector2 } from "three";

import Experience from "../Experience";
import EventEmitter from "../Utils/EventEmitter";

export default class Minimap extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();

        // Set variables
        this.instance = {};
        // Set array of sections
        this.instance.sections = [];
        this.instance.container = null;

        // Last and current section id
        this.lastSectionId = null;
        this.currentSectionId = null;
        // Current section coordinations inside the grid
        this.currentSectionCoords = [];
        // Side from which the user will enter the section
        this.sideToEnter = null;
    }

    // Method called to create and set up the minimap
    setMinimap(sections, startingSection)
    {
        // Get number of sections needed
        this.instance.numOfSections = sections.length;
        // Set player position in the minimap
        this.currentSectionId = startingSection;

        // Get container
        this.instance.container = document.getElementById('minimap-container');

        // Only create minimap if there is more than one section
        if(this.instance.numOfSections > 1)
        {
            // Show minimap
            this.instance.container.style.display = 'grid';
            // Set rows and columns
            this.#setRowsAndColumns();
            // Set default section
            this.#setDefaultSection(sections[0]);
            // Set other sections
            this.#setOtherSections(sections);
            // Set the player arrow
            this.#setArrow();

            // Split the expando property value
            const split = this.instance.sections[startingSection].attributes['grid-position'].value.split('-');
            // Get the coordinates from the split value
            const coords = [parseInt(split[0]), parseInt(split[1])];

            // Get the current section coordinates on the grid
            this.currentSectionCoords = [coords[0], coords[1]];
        }
        else this.instance.container.style.display = 'none';
    }

    // Method called to return the section id by a certain direction from the current section
    getSectionIdByDirection(direction)
    {
        if(this.instance.numOfSections > 1)
        {
            // Clone the current section's coordinates
            let coordinates = this.currentSectionCoords.slice();

            // If the desired direction is above
            if(direction === 'n')
            {
                // Reduce the row coordinate by 1
                coordinates[1] -= 1;
            }
            // If the desired direction is below
            else if(direction === 's')
            {
                // Increade the row coordinate by 1
                coordinates[1] += 1;
            }
            // If the desired direction is to the right
            else if(direction === 'e')
            {
                // Increade the column coordinate by 1
                coordinates[0] += 1;
            }
            // If the desired direction is to the left
            else if(direction === 'w')
            {
                // Reduce the column coordinate by 1
                coordinates[0] -= 1;
            }

            // For each of the sections registered
            for(let i = 0; i < this.instance.numOfSections; i++)
            {
                // Split the expando property value
                const split = this.instance.sections[i].attributes['grid-position'].value.split('-');
                // Get the coordinates from the split value
                const coords = [parseInt(split[0]), parseInt(split[1])];

                // If the section coordinates are compatible with the target coordinates
                if(coordinates[0] == coords[0] && coordinates[1] == coords[1])
                {
                    // Return the section id
                    return this.instance.sections[i].attributes['id'].value;
                }
            }
        }
        else return undefined;
    }

    // Private method called to set the grid's rows and columns
    #setRowsAndColumns()
    {
        // Get the number of rows and columns needed
        this.instance.rows = 1;
        this.instance.columns = 1;

        // Get number of sections
        const sections = this.instance.numOfSections;

        // Set number of rows
        if(sections > 3 && sections <= 6)
        {
            this.instance.rows = 2;
        }
        else if(sections > 6 && sections <= 15)
        {
            this.instance.rows = 3;
        }
        else if(sections > 15 && sections <= 20)
        {
            this.instance.rows = 4;
        }
        else if(sections > 20)
        {
            this.instance.rows = 5;
        }

        // Set number of columns
        if(sections == 2)
        {
            this.instance.columns = 2;
        }
        else if(sections > 2 && sections <= 9)
        {
            this.instance.columns = 3;
        }
        else if(sections == 10)
        {
            this.instance.columns = 4;
        }
        else if(sections > 10)
        {
            this.instance.columns = 5;
        }

        // Set base template
        this.instance.container.style.gridTemplateRows = '30px';
        this.instance.container.style.gridTemplateColumns = '30px';

        // For each of the rows
        for(let h = 1; h < this.instance.rows; h++)
        {
            // Add a row to the container
            this.instance.container.style.gridTemplateRows += ' 30px';
        }
        // For each of the columns
        for(let w = 1; w < this.instance.columns; w++)
        {
            // Add a column to the container
            this.instance.container.style.gridTemplateColumns += ' 30px';
        }
    }

    // Private method called to set the default minimap section, where the user always start
    #setDefaultSection(pavSection)
    {
        // Get default position
        this.instance.defaultPos = [1, 1];

        // Set the default row coordinate at the base of the minimap
        this.instance.defaultPos[1] = this.instance.rows;

        // Get the default column coordinate at the center of the minimap
        if(this.instance.columns == 2)
        {
            this.instance.defaultPos[0] = 2;
        }
        else if(this.instance.columns == 3 || this.instance.columns == 5)
        {
            this.instance.defaultPos[0] = Math.round(this.instance.columns / 2);
        }
        else if(this.instance.columns == 4)
        {
            this.instance.defaultPos[0] = 3;
        }

        // Set array of sections
        this.instance.sections = [];

        // Create the default section in the minimap
        const mainSection = document.createElement('div');
        mainSection.style.backgroundColor = '#ffffff15';
        mainSection.style.border = '1px solid #d7d7e0';
        mainSection.innerHTML = pavSection.section_name;

        // Set row and column coordinates
        mainSection.style.gridColumn = this.instance.defaultPos[0];
        mainSection.style.gridRow = this.instance.defaultPos[1];

        // Set id and class
        mainSection.setAttribute('id', 'section_0');
        mainSection.setAttribute('class', 'minimapSection');
        // Set an expando property with the section coordinates on the grid
        mainSection.setAttribute('grid-position', this.instance.defaultPos[0] + '-' + this.instance.defaultPos[1]);

        // Listen for clicks on the section
        mainSection.onclick = (e) =>
        {
            // Get the pavilion class if needed
            if(!this.pavilion) this.pavilion = this.experience.pavilion;

            if(e.target.id.split('_')[1] != this.currentSectionId && this.pavilion.loaded === true)
            {
                // Set player position in the minimap
                this.#setPlayerPosition(e);
                // Trigger switch section event
                this.trigger('switchSection');
            }
        };

        // Add to sections array
        this.instance.sections.push(mainSection);
        // Add to grid container
        this.instance.container.appendChild(mainSection);
    }

    // Private method called to set the other minimap sections
    #setOtherSections(pavSections)
    {
        // Get all positions
        let positions =
        [
            // 0 [Default]
            [0, 0],

            // 1 to 5
            [-1, 0], // Column, Row
            [1, 0],
            [0, -1],
            [-1, -1],
            [1, -1],

            // 6 to 8
            [0, -2], // Column, Row
            [-1, -2],
            [1, -2],

            // 9 to 14
            [-2, 0], // Column, Row
            [2, 0],
            [-2, -1],
            [2, -1],
            [-2, -2],
            [2, -2],

            // 15 to 19
            [0, -3], // Column, Row
            [-1, -3],
            [1, -3],
            [-2, -3],
            [2, -3],

            // 20 to 24
            [0, -4], // Column, Row
            [-1, -4],
            [1, -4],
            [-2, -4],
            [2, -4],
        ]

        // For each of the sections
        for(let i = 1; i < this.instance.numOfSections; i++)
        {
            // Get row and column coordinates
            const column = this.instance.defaultPos[0] + positions[i][0];
            const row = this.instance.defaultPos[1] + positions[i][1];

            // Create a new section in the minimap
            const section = this.instance.sections[0].cloneNode(true);
            section.style.backgroundColor = '#ffffff15';
            section.style.border = '1px solid #d7d7e0';
            section.innerHTML = pavSections[i].section_name;

            // Set id and class
            section.setAttribute('id', 'section_' + i);
            // Set an expando property with the section coordinates on the grid
            section.setAttribute('grid-position', column + '-' + row);
            
            // Set row and column coordinates
            section.style.gridColumn = column;
            section.style.gridRow = row;

            // Listen for clicks on the section
            section.onclick = (e) =>
            {
                // Get the pavilion class if needed
                if(!this.pavilion) this.pavilion = this.experience.pavilion;

                if(e.target.id.split('_')[1] != this.currentSectionId && this.pavilion.loaded === true)
                {
                   // Set player position in the minimap
                    this.#setPlayerPosition(e);
                    // Trigger switch section event
                    this.trigger('switchSection'); 
                }
            };

            // Add to sections array
            this.instance.sections.push(section);
            // Add to grid container
            this.instance.container.appendChild(section);
        }
    }

    // Private method called to create and set up the minimap arrow
    #setArrow()
    {
        // Create arrow image
        this.arrow = document.createElement('img');
        this.arrow.setAttribute('id', 'arrow');
        this.arrow.src = this.experience.parameters.ASSETS_PATH + 'images/icons/arrow.png';

        // Append arrow to the default section
        this.instance.sections[this.currentSectionId].appendChild(this.arrow);

        if(this.experience.camera.FIRST_PERSON_CAM === true) this.arrow.style.display = '';
        else this.arrow.style.display = 'none';
    }

    // Private method called to set the player position in the minimap
    #setPlayerPosition(e)
    {
        // If the current position isn't the same as the clicked position
        if(this.currentSectionId !== parseInt(e.target.id.split('_')[1]))
        {
            // Get last position id
            this.lastSectionId = this.currentSectionId;
        }

        // Save as last position
        this.currentSectionId = parseInt(e.target.id.split('_')[1]);

        // Remove arrow from the last section
        this.arrow.remove();
        // Add arrow to the current section
        this.instance.sections[this.currentSectionId].appendChild(this.arrow);

        // Split the expando property value
        const split = e.target.attributes['grid-position'].value.split('-');
        // Get the coordinates from the split value
        const coords = [parseInt(split[0]), parseInt(split[1])];
        
        // If the current section coordinates are different from the new coordinates
        if(this.currentSectionCoords != coords)
        {
            // If the new section is to the right of the current section
            if(coords[0] > this.currentSectionCoords[0])
            {
                // Enter from the left side of the pavilion
                this.sideToEnter = new Vector2(0, 50);
            }
            // If the new section is to the left of the current section
            else if(coords[0] < this.currentSectionCoords[0])
            {
                // Enter from the right side of the pavilion
                this.sideToEnter = new Vector2(0, -50);
            }

            // If the new section is below the current section
            if(coords[1] > this.currentSectionCoords[1])
            {
                // Enter from the top side of the pavilion
                this.sideToEnter = new Vector2(-45, 0);
            }
            // If the new section is above the current section
            else if(coords[1] < this.currentSectionCoords[1])
            {
                // Enter from the bottom side of the pavilion
                this.sideToEnter = new Vector2(45, 0);
            }
        }

        // Set the current coordinates as the new coordinates
        this.currentSectionCoords = coords;
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Remove all the sections from the document
        this.instance.sections.forEach(section =>
        {
            // Remove click listener
            section.removeAttribute("onclick");
            section.remove();
        });

        // Disable container
        this.instance.container.style.display = 'none';
        this.instance.container.style.gridTemplateRows = '0px';
        this.instance.container.style.gridTemplateColumns = '0px';

        // Reset variables
        this.instance = null;
        this.lastSectionId = null;
        this.currentSectionId = null;
        this.currentSectionCoords = null;
        this.sideToEnter = null;

        // Remove references
        this.pavilion = null;
        this.experience = null;
    }
}