import { Box3, Vector3, BoxBufferGeometry, Mesh, MeshBasicMaterial, MeshStandardMaterial, DoubleSide, sRGBEncoding } from "three";

import Experience from "../Experience.js";
import EventEmitter from "../Utils/EventEmitter.js";
import StationaryNPC from "./StationaryNPC.js";

export default class Stand extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.resources = this.experience.resources;
        this.disposer = this.experience.disposer;
        this.colliders = this.experience.colliders;

        // Create new instance
        this.instance = {};
        // Create new instance of custom objects
        this.instance.customObjs = {};
        // Create array of NPCs
        this.instance.npcs = [];

        // Initialize the JSON file
        this.#initJSONfile();
    }

    // Private method called to initialize the local JSON object
    #initJSONfile()
    {
        // JSON object containing the stand customization info
        this.instance.info =
        {
            "stand_id": "",
            "stand_position": "",
            "stand_style": "",

            "logo": "",

            "tooltip": 
            {
                "name": "",
                "logo": ""
            },

            "main_color": "",
            "sec_color": "",
            "base_color": "",

            "image_screen_0": "",
            "image_screen_1": "",

            "image_orientation": "h",

            "video_screen_0": "",
            "video_screen_1": "",

            "totem_about": false,
            "totem_brochures": false,
            "totem_huddle": false,
            "totem_video": false,

            "npcs_preset":  0,
            "npcs":
            [
                {
                    "position":  0,
                    "style":  0,
                    
                    "hair_color":  "",
                    "top_color":  "",
                    "bottom_color":  "",
                    "shoes_color":  ""
                }
            ]
        };
    }

    // Method called externally to set the stand position
    setPosition(id, pos)
    {
        // Save the stand position
        this.instance.position = id;
        // Set the model coordinates
        this.instance.model.position.copy(pos);
    }

    // Method called externally to set the stand rotation
    setRotation(rot)
    {
        // Set the model quaternior rotation
        this.instance.model.rotation.copy(rot);
    }

    // Method called externally to set the stand collider
    setColliders()
    {
        // Get stand base element
        let base;
        if(this.instance.model.getObjectByName('base_pequena', true))
        {
            base = this.instance.model.getObjectByName('base_pequena', true);
        }
        else
        {
            base = this.instance.model.getObjectByName('base_grande', true);
        }

        // Create box from the base element
        let bbox = new Box3().setFromObject(base);
        // Make a BoxBufferGeometry of the same size as the box
        const dimensions = new Vector3().subVectors( bbox.max, bbox.min );
        const boxGeo = new BoxBufferGeometry(dimensions.x, 5, dimensions.z);
        
        // Create invisible collider
        const col = new Mesh(boxGeo, new MeshBasicMaterial({ visible: false }));
        col.name = "stand_" + this.instance.arrayId + "_collider";
        col.position.set(this.instance.model.position.x, 2.5, this.instance.model.position.z);
        col.rotation.set(this.instance.model.rotation.x, this.instance.model.rotation.y, this.instance.model.rotation.z);

        // Save collider
        this.instance.collider = col;
        // Add new collider to the colliders array
        this.colliders.addCollider(this.instance.collider);
    }

    // Method called to update many or all of the objects
    updateObjectsByJSON(id, jsonObj, glassMat)
    {
        // Set array id
        this.instance.arrayId = id;
        // Set instance JSON file
        this.instance.info = jsonObj;

        // Get glass material
        if(glassMat !== undefined) this.glassMat = glassMat;

        // Go through all the JSON objects
        Object.keys(this.instance.info).forEach((key) =>
        {
            // Apply changes to the stand model
            this.#applyChangesToObj(key, this.instance.info[key]);
        });

        // Update materials
        this.#updateMaterials();
    }

    #applyChangesToObj(key, value)
    {
        // Set stand id
        if(key === 'stand_id')
        {
            this.#setId(value);
        }
        // Set stand model
        else if(key === 'stand_style')
        {
            this.#setStand(value);
        }
        // Set stand tooltip info
        else if(key === 'tooltip')
        {
            this.#setTooltipInfo(value);
        }

        // If the stand model is loaded
        if(this.instance.model !== undefined)
        {
            // Set logo
            if(key === 'logo')
            {
                this.#setLogo(value, false);
            }
            // Set image screen
            else if(key === 'image_screen_0' || key === 'image_screen_1')
            {
                this.#setImage(key.split('_')[2], value, false);
            }
            // Set image screens orientation
            else if(key === 'image_orientation')
            {
                this.#setImageOrientation(value);
            }
            // Set video screen
            else if(key === 'video_screen_0' || key === 'video_screen_1')
            {
                this.#setVideoImage(key.split('_')[2], value, false);
            }
            // Set main color
            else if(key === 'main_color')
            {
                this.#setColor(this.instance.customObjs.mainColorMaterials, value);
            }
            // Set secondary color
            else if(key === 'sec_color')
            {
                this.#setColor(this.instance.customObjs.secColorMaterials, value);
            }
            // Set base color
            else if(key === 'base_color')
            {
                this.#setColor(this.instance.customObjs.baseColorMaterials, value);
            }
            // Set NPCs
            else if(key === "npcs")
            {
                try
                {
                    // If the NPCs preset isn't valid, throw exceptions
                    if(isNaN(this.instance.info.npcs_preset) || this.instance.info.npcs_preset === "")  throw "'npcs_preset' value is not a number: " + this.instance.info.npcs_preset + " (at stand id: " + this.instance.id + ")";
                    if(this.instance.info.npcs_preset < 0 || this.instance.info.npcs_preset > 2) throw "'npcs_preset' value is not a valid number (0 to 2): " + this.instance.info.npcs_preset + " (at stand id: " + this.instance.id + ")";

                    // If the length is above zero
                    if(value.length > 0)
                    {
                        // For each of the values
                        for(let i = 0; i < value.length; i++)
                        {
                            // If it's under the limit
                            if(i < this.npcsLimit)
                            {
                                // If the npc values aren't valid, throw exceptions
                                if(typeof value[i] === 'object')
                                {
                                    if(isNaN(value[i].position) || value[i].position === "") throw "'npcs' 'position' value is not a number: " + value[i].position + " (at stand id: " + this.instance.id + ")";
                                    if(value[i].position < 0 || value[i].position > 4) throw "'npcs' 'position' value is not a valid number (0 to 4): " + value[i].position + " (at stand id: " + this.instance.id + ")";
                                
                                    // Get the respective point from the stand
                                    const point = this.instance.customObjs.npcPoints[this.instance.info.npcs_preset][value[i].position];
                                    // Set new NPC
                                    this.#setNPC(point, value[i]);
                                }
                            }
                        }
                    }
                }
                // Catch errors
                catch(e)
                {
                    console.log(e);
                }
            }
        }
        // If the stand model isn't loaded
        else if(key !== 'tooltip' && key !== 'stand_id' && key !== 'stand_style' && key !== 'stand_position')
        {
            console.log("The stand model could not be loaded, further customization interrupted (at stand id: " + this.instance.id + ")");
        }
    }

    // Method called to set the stand id
    #setId(id)
    {
        try
        {
            // If the id is not valid, throw an exception
            if(id === "" || id === null || id === undefined) throw "'stand_id' value is not valid: " + id;

            // Set instance id
            this.instance.id = id;
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Method called to set the stand tooltip info
    #setTooltipInfo(obj)
    {
        // Create tooltip object
        this.instance.tooltip = {};

        try
        {
            // If the name or the logo are not valid, throw an exception
            if(obj.name === "" || obj.name === null || obj.name === undefined) throw "'tooltip' 'name' value is not defined: " + obj.name + "' (at stand id: " + this.instance.id + ")";
            if(obj.logo === "" || obj.logo === null || obj.logo === undefined) throw "'tooltip' 'logo' value is not defined: " + obj.logo + "' (at stand id: " + this.instance.id + ")";

            // Set instance tooltip info
            this.instance.tooltip.name = obj.name;
            this.instance.tooltip.logo = obj.logo;
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to create and set up the stand
    #setStand(modelId)
    {
        try
        {
            // Get model
            const model = "stand_" + modelId;

            // If the model id is not valid, throw an exception
            if(this.resources.items[model] === undefined) throw "'stand_style' value is not valid: '" + modelId + "' (at stand id: " + this.instance.id + ")";

            this.instance.model = this.resources.items[model].scene.clone();
            this.instance.model.name = "stand_" + this.instance.arrayId;

            // Set default materials
            this.instance.customObjs.defaultMaterial = new MeshBasicMaterial({ color: 0x999999, side: DoubleSide });

            // Get all the customizable objects
            this.#setCustomObjects();

            // Update materials
            this.#updateMaterials();

            // Get the scene from the experience
            if(!this.scene) this.scene = this.experience.scene;
            // Add to scene
            this.scene.add(this.instance.model);
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to load the texture to the logo screens
    #setLogo(url, generateMipmaps)
    {
        try
        {
            // If the value isn't empty
            if(url !== "" && url !== null && url !== undefined)
            {
                // Load logo texture
                const texture = this.resources.loaders.textureLoader.load(url);
                texture.flipY = false;
                texture.generateMipmaps = generateMipmaps;

                // Set texture to the logo material
                this.instance.customObjs.logoMaterial.map = texture;
            }
            // If the value is empty
            else
            {
                // Load default material
                this.instance.customObjs.logoMaterial = this.instance.customObjs.defaultMaterial;
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to load the texture to the image screens
    #setImage(id, url, generateMipmaps)
    {
        try
        {
            // If the value isn't empty
            if(url !== "" && url !== null && url !== undefined)
            {
                // Load texture
                const texture = this.resources.loaders.textureLoader.load(url);
                texture.flipY = false;
                texture.generateMipmaps = generateMipmaps;

                // Set material encoding
                let newMaterial = new MeshBasicMaterial({ map: texture, side: DoubleSide });
                newMaterial.map.encoding = sRGBEncoding;
                newMaterial.encoding = sRGBEncoding;
                // Set material to the image screen
                this.instance.customObjs.imageScreens[id].material = newMaterial;
                this.instance.customObjs.imageScreens[id].material.needsUpdate = true;

                // Add screen to the interactable objects array
                this.instance.interactableObjects.push(this.instance.customObjs.imageScreens[id]);

                // If this stand supports multiple image screen orientations
                if(this.instance.customObjs.imageScreenVariations.length > 2)
                {
                    // Also set the material to the image screen of same id but opposite orientation
                    this.instance.customObjs.imageScreenVariations.forEach(screen =>
                    {
                        // Get the image from the opposite orientation
                        let name = screen.name.split('_');
                        if(name[1] == id && name[2].split('')[0] != this.instance.info.imageScreenOrientation)
                        {
                            // Set material to the image screen
                            screen.material = newMaterial;
                            screen.material.needsUpdate = true;

                            // Add screen to the interactable objects array
                            this.instance.interactableObjects.push(screen);
                        }
                    });
                }
            }
            // If the value is empty
            else
            {
                // Load default material
                this.instance.customObjs.imageScreens[id].material = this.instance.customObjs.defaultMaterial;

                // Remove from the interactable objects array
                this.instance.interactableObjects = this.instance.interactableObjects.filter((value, index, arr) =>
                { 
                    return value.name != this.instance.customObjs.imageScreens[id].name;
                });
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
        
    }

    // Private method called to activate the correct image screen orientation
    #setImageOrientation(value)
    {
        try
        {
            // If the image orientation value is not valid, throw an exception
            if(value !== "" && value !== null && value !== undefined)
            {
                if(value !== 'h' && value !== 'v') throw "'image_orientation' value is not a valid string ('h' or 'v'): '" + value + "' (at stand id: " + this.instance.id + ")";
            
                // If this stand supports multiple image screen orientations
                if(this.instance.customObjs.imageScreenVariations.length > 2)
                {
                    // Go through all image screens
                    this.instance.customObjs.imageScreenVariations.forEach(screen =>
                    {
                        // Get name and segment
                        let seg, name = screen.name.split('_');
                        if(name[0] == "imageScreen") seg = 2;
                        else if(name[0] == "imageScreenBase") seg = 1;

                        // If the first letter of the orientation matches the new orientation
                        if(name[seg].split('')[0] == value)
                        {
                            // Enable screen
                            screen.visible = true;
                        }
                        // If the first letter of the orientation doesn't match the new orientation
                        else
                        {
                            // Disable screen
                            screen.visible = false;
                        }
                    });
                }
            }
            else
            {
                // Load default material
                this.instance.customObjs.imageScreens[id].material = this.instance.customObjs.defaultMaterial;

                // Remove from the interactable objects array
                this.instance.interactableObjects = this.instance.interactableObjects.filter((value, index, arr) =>
                { 
                    return value.name != this.instance.customObjs.imageScreens[id].name;
                });
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }
    
    // Private method called to load the video thumbnail to the video screens
    #setVideoImage(id, url, generateMipmaps)
    {
        try
        {
            // If the value isn't empty
            if(url !== "" && url !== null && url !== undefined)
            {
                // Load texture
                const texture = this.resources.loaders.textureLoader.load(url);
                texture.flipY = false;
                texture.generateMipmaps = generateMipmaps;

                // Load play button texture
                const texturePlay = this.resources.items.playButton;
                texturePlay.flipY = false;
                texturePlay.generateMipmaps = generateMipmaps;

                // Set thumbnail material encoding
                let materialThumbnail = new MeshBasicMaterial({ map: texture, side: DoubleSide });
                materialThumbnail.map.encoding = sRGBEncoding;
                materialThumbnail.encoding = sRGBEncoding;
                // Set play material encoding
                let materialPlay = new MeshBasicMaterial({ map: texturePlay, side: DoubleSide, transparent: true });
                materialPlay.map.encoding = sRGBEncoding;
                materialPlay.encoding = sRGBEncoding;
                
                // Create groups to load this materials, from the first pixel to the infinity
                this.instance.customObjs.videoScreens[id].geometry.addGroup( 0, Infinity, 0 );
                this.instance.customObjs.videoScreens[id].geometry.addGroup( 0, Infinity, 1 );
                // Add materials to the screen
                this.instance.customObjs.videoScreens[id].material = [materialThumbnail, materialPlay];
                this.instance.customObjs.videoScreens[id].material.needsUpdate = true;

                // Add screen to the interactable objects array
                this.instance.interactableObjects.push(this.instance.customObjs.videoScreens[id]);
            }
            // If the value is empty
            else
            {
                // Load default material
                this.instance.customObjs.videoScreens[id].material = this.instance.customObjs.defaultMaterial;

                // Remove from the interactable objects array
                this.instance.interactableObjects = this.instance.interactableObjects.filter((value, index, arr) =>
                { 
                    return value.name != this.instance.customObjs.videoScreens[id].name;
                });
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to set the color code to the colored materials
    #setColor(array, hexcode)
    {
        try
        {
            // If the hexcode is empty, set default value
            if(hexcode !== "" && hexcode !== null && hexcode !== undefined)
            {
                // If the hexcode is on the wrong format
                if(hexcode.slice(0, 1) === '#')
                {
                    // Set to correct format
                    hexcode = '0x' + hexcode.slice(1);
                }

                // Set the color to all materials
                array.forEach(material =>
                {
                    // Set hexcode
                    material.color.setHex(hexcode).convertSRGBToLinear();
                    material.needsUpdate = true;
                });
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to set the NPCs on their respective spots
    #setNPC(point, json)
    {
        try
        {
            // If the npc style isn't valid, throw exceptions
            if(this.resources.items["npc_" + json.style] === undefined) throw "'npcs' 'style' value is not a valid number (0 to 4): " + json.style;
            
            // Create new NPC
            const npc = new StationaryNPC();

            // Set style, position and rotation
            npc.setStyle(json.style);
            npc.setPosition(point);

            npc.setColor("hair_color", json.hair_color);
            npc.setColor("top_color", json.top_color);
            npc.setColor("bottom_color", json.bottom_color);
            npc.setColor("shoes_color", json.shoes_color);

            // If the NPC is part of a talking group
            if(npc.instance.talkGroup !== undefined)
            {
                // Go through each of the set NPCs
                this.instance.npcs.forEach(companion =>
                {
                    // If there is a NPC in the same talk group
                    if(companion.instance.talkGroup === npc.instance.talkGroup)
                    {
                        // Set talking animations to both of them
                        companion.instance.talking = true;
                        npc.instance.talking = true;
                    }
                });
            }

            // Add to the NPCs array
            this.instance.npcs[json.position] = npc;
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to get all the customizable objects from the stand model
    #setCustomObjects()
    {
        // Reset all variables
        this.instance.activeHolograms = [];
        this.instance.interactableObjects = [];
        this.instance.customObjs.logoMaterial = null;
        this.instance.customObjs.imageScreens = [];
        this.instance.customObjs.imageScreenVariations = [];
        this.instance.customObjs.imageScreenBases = [];
        this.instance.customObjs.videoScreens = [];
        this.instance.customObjs.mainColorMaterials = [];
        this.instance.customObjs.secColorMaterials = [];
        this.instance.customObjs.baseColorMaterials = [];
        this.instance.customObjs.npcPoints = [[], [], []];

        // Go through all the model's children
        this.instance.model.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof Mesh)
            {
                // Get the child's splitted name
                const splitObj = child.name.split("_");
                const splitMat = child.material.name.split("_");

                // Get logo screen
                if(splitObj[0] == "logoScreen")
                {
                    // Set each logo screen material encoding
                    if(this.instance.customObjs.logoMaterial === null)
                    {
                        // Create logo material
                        child.material = new MeshBasicMaterial({ side: DoubleSide, transparent: true });
                        this.instance.customObjs.logoMaterial = child.material;
                    }
                    // Use already existing logo material
                    else child.material = this.instance.customObjs.logoMaterial;
                }
                // Set image screen
                else if(splitObj[0] == "imageScreen")
                {
                    child.material = child.material.clone();

                    child.material.side = DoubleSide;
                    // Add to general array
                    this.instance.customObjs.imageScreenVariations.push(child);

                    // If the stand have multiple image screen orientations
                    if(splitObj[2])
                    {
                        // Add to the image array only the screens in the correct orientation
                        if(splitObj[2].split('')[0] == this.instance.info.image_orientation)
                        {
                            this.instance.customObjs.imageScreens[splitObj[1]] = child;
                        }
                        // Disable unused image screens
                        else child.visible = false;
                    }
                    // If the stand doesn't have multiple image screen orientations, add screen to image array directly
                    else this.instance.customObjs.imageScreens[splitObj[1]] = child;
                }
                // Set video screen
                else if(splitObj[0] == "videoScreen")
                {
                    child.material = child.material.clone();

                    child.material.side = DoubleSide;
                    this.instance.customObjs.videoScreens[splitObj[1]] = child;
                }
                // Set main color
                else if(splitMat[0] == "mainColor")
                {
                    child.material = child.material.clone();
                    this.instance.customObjs.mainColorMaterials.push(child.material);
                }
                // Set secondary color
                else if(splitMat[0] == "secColor")
                {
                    child.material = child.material.clone();
                    this.instance.customObjs.secColorMaterials.push(child.material);
                }
                // Set base color
                else if(splitMat[0] == "thirdColor")
                {
                    child.material = child.material.clone();
                    this.instance.customObjs.baseColorMaterials.push(child.material);
                }
                // If the child contains a glass material
                else if(child.material.name === "Glass")
                {
                    // Set default glass material
                    if(this.glassMat) child.material = this.glassMat;
                }
            }

            // Set the image orientation
            if(child.name.split("_")[0] == "imageScreenBase")
            {
                this.instance.customObjs.imageScreenVariations.push(child);
                // Disable the unused base
                if(child.name.split("_")[1].split('')[0] != this.instance.info.image_orientation) child.visible = false;
            }
            // Set the NPC points
            else if(child.name.split("_")[0] == "npcPoint")
            {
                let id = child.name.split("_")[1];
                this.instance.customObjs.npcPoints[id].push(child);
            }
        });

        // Get the NPC limit
        this.npcsLimit = this.instance.customObjs.npcPoints[0].length;
    }

    // Private method called to update the model materials
    #updateMaterials()
    {
        // If the stand model is loaded
        if(this.instance.model !== undefined)
        {
            // Go through all the model's children
            this.instance.model.traverse((child) =>
            {
                // If child is a mesh object and their material is a standard material
                if(child instanceof Mesh && child.material)
                {
                    // Receive and cast shadows
                    child.receiveShadow = true;
                    child.castShadow = true;
        
                    // Set children material encoding
                    child.material.encoding = sRGBEncoding;
                    if(child.material.map != null) child.material.map.encoding = sRGBEncoding;
                    child.material.needsUpdate = true;
                }
            });
        }
    }

    // Method propagated by the pavilion class each tick event
    update(delta)
    {
        // If there are active NPCs
        if(this.instance.npcs.length > 0)
        {
            // If the first person camera is active
            if(this.experience.camera.FIRST_PERSON_CAM === true)
            {
                // For each of the NPCs
                this.instance.npcs.forEach(npc =>
                {
                    // If the NPC is an instance of the NPC class
                    if(npc instanceof StationaryNPC)
                    {
                        // If the NPC is near enough to the player
                        if(this.instance.model.position.distanceTo(this.experience.player.instance.collider.end) < 40)
                        {
                            // Set it to be visible
                            npc.instance.model.visible = true;
                            // Update
                            npc.update(delta);
                        }
                        // If the NPC is too far from the player
                        else
                        {
                            // Set it to be invisible
                            npc.instance.model.visible = false;
                        }
                    }
                });
            }
            // If the first person camera isn't active
            else
            {
                // For each of the NPCs
                this.instance.npcs.forEach(npc =>
                {
                    // If the NPC is an instance of the NPC class
                    if(npc instanceof StationaryNPC)
                    {
                        // Set it to be invisible
                        npc.instance.model.visible = false;
                    }
                });
            }
        }
    }

    // Method called to dispose the stand
    disposeStand()
    {
        // Dispose the stand collider
        this.disposer.disposeElements(this.instance.collider);
        // Dispose the model
        this.disposer.disposeElements(this.instance.model);
        // Destroy NPCs
        this.instance.npcs.forEach(npc =>
        {
            npc.destroy();
        });

        // Reset arrays length
        this.instance.activeHolograms.length = 0;
        this.instance.interactableObjects.length = 0;
        this.instance.customObjs.logoMaterial = null;
        this.instance.customObjs.imageScreens.length = 0;
        this.instance.customObjs.imageScreenVariations.length = 0;
        this.instance.customObjs.imageScreenBases.length = 0;
        this.instance.customObjs.videoScreens.length = 0;
        this.instance.customObjs.mainColorMaterials.length = 0;
        this.instance.customObjs.secColorMaterials.length = 0;
        this.instance.customObjs.baseColorMaterials.length = 0;
        this.instance.customObjs.npcPoints.length = 0;

        // Reset the instance
        this.instance = null;
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Dispose the stand
        this.disposeStand();

        // Remove references
        this.experience = null;
        this.scene = null;
        this.resources = null;
        this.disposer = null;
        this.colliders = null;
    }
}