import Experience from "../Experience.js";
import EventEmitter from './EventEmitter.js';

let keyDownHandler, keyUpHandler;

export default class Keys extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.mobileDetector = this.experience.mobileDetector;

        // If the device is a desktop
        if(!this.mobileDetector.isMobile)
        {
            // Set key states array
            this.keyStates = [];

            // Set the key listeners
            this.#setListeners();
        }
        // If the device is a mobile
        else
        {
            // Set buttons to replace the keys
            this.#setButtons();
        }
        // Remove reference
        this.mobileDetector = null;
    }

    // Method called by external classes to set the listeners
    #setListeners()
    {
        // Key down event handler
        keyDownHandler = (e) =>
        {
            // If the scene is loaded
            if(this.experience.SCENE_LOADED)
            {
                // Set the key state
                this.keyStates[e.code] = true;

                // Trigger switch camera command
                if(e.code == 'KeyC')
                {
                    this.trigger('switchCamera');
                }
                // Trigger update render quality command
                else if(e.code == 'Digit1')
                {
                    // Update to low quality
                    this.experience.updateRenderQuality(0);
                }
                // Trigger update render quality command
                else if(e.code == 'Digit2')
                {
                    // Update to medium quality
                    this.experience.updateRenderQuality(1);
                }
                // Trigger update render quality command
                else if(e.code == 'Digit3')
                {
                    // Update to high quality
                    this.experience.updateRenderQuality(2);
                }
            }
        };

        // Key up event handler
        keyUpHandler = (e) =>
        {
            // Reset the key state
            this.keyStates[e.code] = false;
        };

        // Listen for pressed keys
        document.addEventListener('keydown', keyDownHandler);
        // Listen for released keys
        document.addEventListener('keyup', keyUpHandler);
    }

    // Private method called to set up buttons to replace the keys
    #setButtons()
    {
        // Get buttons
        const switchButton = document.getElementById('switch-camera-mode');
        // Listen for clicks
        switchButton.onclick = () =>
        {
            // Trigger switch camera event
            this.trigger('switchCamera');
        };
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Stop listening for pressed keys
        document.removeEventListener('keydown', keyDownHandler);
        // Stop listening for released keys
        document.removeEventListener('keyup', keyUpHandler);
        // Remove onclick events
        document.getElementById('switch-camera-mode').removeAttribute("onclick");

        // Destroy references
        this.experience = null;
        keyDownHandler = null;
        keyUpHandler = null;
    }
}